import { gql } from '@apollo/client';

export const GET_USER_ACCOUNT_DETAILS = gql`
  query getSellerAccountDetails {
    getSellerAccountDetails {
      identifier
      name
      email
      tncAccepted
      efiAuditor
    }
  }
`;

export const UPDATE_USER_ACCOUNT = gql`
  mutation updateOneSellerAccounts($email: ID!) {
    updateOneSellerAccounts(input: { id: $email, update: { tncAccepted: true } }) {
      email
    }
  }
`;
