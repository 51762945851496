import { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { getAuthenticatedRoute } from '@c2fo/react-components';
import { ServicesContext } from '../services';
import { LoginPage } from '../pages/LoginPage/LoginPage';
import { RepaymentPage } from '../pages/RepaymentPage/RepaymentPage';
import { SellersPage } from '../pages/SellersPage/SellersPage';
import { InvoicePage } from '../pages/InvoicePage/InvoicePage';
import { TermsAndConditionsPage } from '../pages/TermsAndConditionsPage/TermsAndConditionsPage';
import { AuthorizationPage } from '../pages/AuthorizationPage/AuthorizationPage';

import { Routes } from '../constants/RouterLink';

export const Router = () => {
  const { authService } = useContext(ServicesContext);
  const AuthenticatedRoute = getAuthenticatedRoute({
    isAuthenticated: () => authService.isAuthenticated(),
    redirectTo: Routes.Login,
  });

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={Routes.Login} component={LoginPage} />
        <AuthenticatedRoute path={Routes.Invoices} component={InvoicePage} />
        <AuthenticatedRoute exact path={Routes.Tnc} component={TermsAndConditionsPage} />
        <AuthenticatedRoute path={Routes.Authorisation} component={AuthorizationPage} />
        <AuthenticatedRoute path={Routes.Repayment} component={RepaymentPage} />
        <AuthenticatedRoute path={Routes.Sellers} component={SellersPage} />
      </Switch>
    </BrowserRouter>
  );
};
