import React, { memo, useState } from 'react';
import { TableCell } from '@c2fo/react-components';
import { TableColumnConfig, TableRowDataProps } from '../../../types/customTable.schema';
import { noCellData } from '../../../constants/Constants';

interface CustomTableRowProps {
  rowData: TableRowDataProps;
  rowIndex: number;
  config: TableColumnConfig[];
}

const CustomTableRow: React.FC<CustomTableRowProps> = ({ rowData, rowIndex, config }) => {
  const [updatedRowDataVariable, setUpdatedRowDataVariable] = useState<Record<string, unknown>>({});

  const row: JSX.Element[] = [];
  config.forEach((heading, cellIndex) => {
    const { hide, align } = config[cellIndex];
    if (hide) return false;

    const { selectorKey, cell } = heading;
    let cellData;
    if (selectorKey) cellData = rowData[selectorKey];
    else if (cell) cellData = cell({ rowData, rowIndex, setUpdatedRowDataVariable, updatedRowDataVariable });

    row.push(
      <TableCell align={align} key={cellIndex}>
        {cellData ?? noCellData}
      </TableCell>,
    );
  });

  return <>{row}</>;
};

export default memo(CustomTableRow);
