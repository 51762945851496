import { ReportingService } from '@c2fo/react-services';
import { COMPONENTS, Error, TRACK_EVENTS } from './EventsService.schema';

export class EventsService {
  constructor(private reportingService: ReportingService) {}

  /**
   * track event: inactivity-monitor::refresh-token::submitted
   * description: the inactivity monitor has submitted a request to refresh the user's auth token
   */
  trackInactivityMonitorRefreshStart(): void {
    this.reportingService.track(`${COMPONENTS.INACTIVITY_MONITOR}::refresh-token::${TRACK_EVENTS.SUBMITTED}`);
  }
  /**
   * track event: inactivity-monitor::refresh-token::success
   * description: inactivity monitor has successfully refreshed the user's auth token
   */

  trackInactivityMonitorRefreshSuccess(): void {
    this.reportingService.track(`${COMPONENTS.INACTIVITY_MONITOR}::refresh-token::${TRACK_EVENTS.SUCCESS}`);
  }
  /**
   * track event: inactivity-monitor::refresh-token::error
   * description: inactivity monitor was unable to refresh the user's auth token
   * data: {
   *   error: error message from the refresh request
   * }
   */

  trackInactivityMonitorRefreshError(data: Error): void {
    this.reportingService.track(`${COMPONENTS.INACTIVITY_MONITOR}::refresh-token::${TRACK_EVENTS.ERROR}`, data);
  }
  /**
   * track event: inactivity-monitor::logout
   * description: inactivity monitor has cleared the user's auth tokens and logged them out
   */

  trackInactivityMonitorLogout(): void {
    this.reportingService.track(`${COMPONENTS.INACTIVITY_MONITOR}::${TRACK_EVENTS.LOGOUT}`);
  }
  /**
   * track event: inactivity-monitor::modal-shown
   * description: the inactivity monitor modal was show to the user to request token refresh
   */

  trackInactivityMonitorModalShown(): void {
    this.reportingService.track(`${COMPONENTS.INACTIVITY_MONITOR}::${TRACK_EVENTS.MODAL_SHOWN}`);
  }
}
