import { gql } from '@apollo/client';
// add creditLimit in bankDetails
export const SELLERS = gql`
  query sellers($sellerName: String, $sortCol: SellerSortFields!, $sortOrder: SortDirection!, $paging: CursorPaging) {
    sellers(
      filter: { name: { iLike: $sellerName } }
      sorting: { field: $sortCol, direction: $sortOrder }
      paging: $paging
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          identifier
          name
          email
          bankDetails {
            id
            bank
            creditLimit
          }
        }
      }
    }
  }
`;
