import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableSortLabel,
  TableContainer,
  AncillaryButton,
} from '@c2fo/react-components';

import InvoiceTableRow from './InvoiceTableRow';
import { defaultRowPerPage, invoiceTableHeaders } from '../../../constants/Constants';
import useStyles from '../../Atoms/CustomTable/styles';
import Error from '../../../../assets/svg/Error.svg';
import NotFound from '../../../../assets/svg/NotFound.svg';
import { SortingCol } from '../../../types/customTable.schema';
/* eslint-disable @typescript-eslint/no-explicit-any */

export const InvoiceTable = ({
  data,
  invoiceStatuses,
  setSortCol,
  sortOrder,
  setSortOrder,
  variant,
  errorMsg,
  maxData = 1,
  keyExtractor,
  onPageChange,
  initialSorting,
  isLoading = false,
  currentSelectedPageNumber,
  rowsPerPage = defaultRowPerPage,
}: any) => {
  const classes = useStyles();
  // Considering lowest page number as 1
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [currentSelectedPage, setCurrentSelectedPage] = useState(currentSelectedPageNumber ?? 0);
  const [sortedCol, setSortedCol] = useState<SortingCol>(initialSorting);

  useEffect(() => {
    setMaxPageNumber(Math.ceil(maxData / rowsPerPage));
  }, [maxData]);

  useEffect(() => {
    if (currentSelectedPageNumber !== undefined && currentSelectedPageNumber !== currentSelectedPage)
      setCurrentSelectedPage(currentSelectedPageNumber);
  }, [currentSelectedPageNumber]);

  const handleChangePage = useCallback(
    (isNextPressed = false) => {
      setCurrentSelectedPage((pre: any) => {
        let currentPageNumber = 0;
        if (isNextPressed) currentPageNumber = pre + 1;
        else currentPageNumber = pre - 1 < 0 ? 0 : pre - 1;
        if (onPageChange) onPageChange({ currentPageNumber, rowsPerPage });
        return currentPageNumber;
      });
    },
    [onPageChange],
  );

  const getTableHeadingRow = () => {
    const tableHeader: JSX.Element[] = [];
    invoiceTableHeaders.forEach((header: any, index: number) => {
      const { columnLabel, columnKey, onSort } = header;
      const isSorted = sortedCol?.colIndex === index;
      tableHeader.push(
        <TableCell key={`header${columnLabel}`} className={classes.header} align={'left'}>
          {onSort === undefined ? (
            columnLabel
          ) : (
            <TableSortLabel
              active={isSorted}
              direction={isSorted ? sortedCol?.direction : 'asc'}
              onClick={() => {
                if (onSort === 'enabled') {
                  const dir = isSorted && sortedCol?.direction === 'asc' ? 'desc' : 'asc';
                  const direction = sortOrder.toLowerCase();
                  if (direction === null) return;
                  setSortedCol({
                    colIndex: index,
                    direction: dir,
                  });
                  setSortCol(columnKey);
                  setSortOrder(dir.toLocaleUpperCase());
                }
              }}
            >
              {columnLabel}
            </TableSortLabel>
          )}
        </TableCell>,
      );
    });

    return tableHeader;
  };

  const getTableBody = () => {
    if (!isLoading && Array.isArray(data) && data.length > 0)
      return (
        <TableBody>
          {data.map((rowData, index) => {
            return (
              <InvoiceTableRow
                key={`${rowData[keyExtractor]}`}
                rowData={rowData}
                rowIndex={index}
                setSortCol={setSortCol}
                setSortOrder={setSortOrder}
                invoiceStatuses={invoiceStatuses}
              />
            );
          })}
        </TableBody>
      );
    return null;
  };

  const isBackDisabled = useCallback(() => {
    return currentSelectedPage === 0;
  }, [currentSelectedPage]);

  const isNextDisabled = useCallback(() => {
    // Table data is empty
    if (!isLoading && !(Array.isArray(data) && data.length > 0)) return true;
    // Is last page
    if (currentSelectedPage === maxPageNumber - 1) return true;
    return false;
  }, [currentSelectedPage, data, isLoading, maxPageNumber]);

  const loadingBody = () => <Typography>Loading</Typography>;

  const noDataBody = () => (
    <Box className={classes.row}>
      <img src={NotFound} className={classes.img} />
      <Typography>{'There are no records to display'}</Typography>
    </Box>
  );

  const errorBody = () => (
    <Box className={classes.row}>
      <img src={Error} className={classes.img} />
      <Typography variant="body1">{`Error: ${
        typeof errorMsg === 'string' ? errorMsg : 'Unable to find data'
      }`}</Typography>
    </Box>
  );

  const fullWidthTableBody = () => {
    let ui;
    if (isLoading) ui = loadingBody();
    else if (Array.isArray(data) && data.length > 0) return null;
    else if (errorMsg) ui = errorBody();
    else if (Array.isArray(data)) ui = noDataBody();

    return (
      <Box
        className={[variant === 'mini' ? classes.minTableHeight : classes.fullTableHeight, classes.tableBody].join(' ')}
      >
        {ui}
      </Box>
    );
  };

  const tablePagination = () => {
    if (!data || data.length === 0) return null;
    if (variant === 'mini')
      return (
        <Box className={classes.miniPagination} onClick={() => handleChangePage(true)}>
          <Typography align="center" className={classes.showMore}>
            {'Show More'}
          </Typography>
        </Box>
      );
    return (
      <Box width={1} className={classes.tableFooter}>
        <AncillaryButton disabled={isBackDisabled()} onClick={() => handleChangePage(false)}>
          BACK
        </AncillaryButton>
        {maxPageNumber > 0 && <Typography>{`${currentSelectedPage + 1} of ${maxPageNumber}`}</Typography>}
        <AncillaryButton disabled={isNextDisabled()} onClick={() => handleChangePage(true)}>
          NEXT
        </AncillaryButton>
      </Box>
    );
  };

  return (
    <>
      {/* <TableFilters filters={filters} currentPageNumber={currentSelectedPage} rowsPerPage={rowsPerPage} /> */}
      <TableContainer className={[classes.tableContainer, variant === 'mini' && classes.minTableContainer].join(' ')}>
        <Table
          className={classes.table}
          style={{
            minWidth: variant === 'mini' ? 'auto' : '700px',
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>{getTableHeadingRow()}</TableRow>
          </TableHead>
          {getTableBody()}
        </Table>
        {fullWidthTableBody()}
        {!isLoading && tablePagination()}
      </TableContainer>
    </>
  );
};
