import { format } from 'date-fns';

/**
 * @function dateFormatter
 * @description Formats the date got from backend to valid frontend formate
 * @returns formatted date or `--` in case of date as null
 */
function dateFormatter(date: string | number, dateFormat = 'MM/dd/yyyy') {
  if (!date) return '--';
  return format(new Date(date), dateFormat);
}

/**
 * @function getChangedValues
 * ---
 * ### Use this to send only the updated key / value pair to backend
 * ---
 * @description function does a shallow comparison between all the values of
 *  `updatedData` and `prevData` object to return only changed key-value.
 * @param prevData {Object}
 * @param updatedData {Object}
 * @return
 * ### All the key of 2nd param with changed values and new keys (compared to 1st param) are returned
 * - Object {...}
 * - empty object {}: in case of no new value found
 */
function getChangedValues(
  prevData: Record<string, unknown>,
  updatedData: Record<string, unknown>,
): Record<string, unknown> {
  const changedValues: Record<string, unknown> = {};

  Object.entries(updatedData).forEach(([key, value]) => {
    const prevValue = prevData[key];
    if (prevValue !== value && value !== undefined) {
      changedValues[key] = value;
    }
  });
  return changedValues;
}

export { getChangedValues, dateFormatter };
