import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Theme,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Liquidity,
  Typography,
  withStyles,
  makeStyles,
  createStyles,
  TableSortLabel,
  TableContainer,
  AncillaryButton,
  Tooltip,
} from '@c2fo/react-components';

import CustomTableRow from './TableRow';
import TableFilters from './TableFilters/TableFilters';
import { defaultRowPerPage } from '../../../constants/Constants';
import { CustomTableProps, SortingCol } from '../../../types/customTable.schema';

import useStyles from './styles';

import Error from '../../../../assets/svg/Error.svg';
import NotFound from '../../../../assets/svg/NotFound.svg';

/**
 * @param {} config configuration of the table column (column header & cell data)
 * @param {} customFilters array of custom filters
 * @param {} filters array of filters (only default filters) (currently it has only Search filter)
 * @param {} maxData max data count (used for pagination count)
 * @param {} onPageChange callback on Next or Prev button click
 * @param {} data array of objects for the table row
 * @param {} isLoading makes table in loading state
 * @param {} noDataText in case table doesn't have any data (data array is empty)
 * @param {} errorMsg in case of error for table list api
 * @param {} keyExtractor pass an api primary key, which is unique for a row data
 * @example
 * <CustomTable
    data={[]}
    config={[
      {
        columnLabel: ('User Name'),
        cell: ({ rowData }) => {
          const {firstName, lastName} = rowData
          return `${firstName} ${lastName}`
        },
      },
      {
        columnLabel: 'Email',
        selectorKey: 'emailAddress',
      },
    ]}
    keyExtractor="userId"
    maxData={totalDataCount}
    noDataText="No Data Found"
    errorMsg={listingApiError && 'Unable to load data'}
    isLoading={listingApiLoading}
    onPageChange={onTablePageChange}
    variant='mini'
    incrementalRowText="show more merchants"
    filters={[
      { type: 'search', props: { onSearch: handelTableAction, label: 'Search User' } }
    ]}
    customFilters={[
       ({ currentPageNumber, rowsPerPage, searchText }: OnPageChangeParams) => <Button>Filter 1</Button>,
       ({ currentPageNumber, rowsPerPage, searchText }: OnPageChangeParams) => <Button>Filter 2</Button>,
     ]}
  />
 */
const CustomTable: React.FC<CustomTableProps> = ({
  data,
  config,
  filters,
  variant,
  errorMsg,
  noDataText,
  maxData = 1,
  keyExtractor,
  onPageChange,
  initialSorting,
  isLoading = false,
  incrementalRowText,
  currentSelectedPageNumber,
  rowsPerPage = defaultRowPerPage,
}) => {
  const classes = useStyles();
  // Considering lowest page number as 1
  const [maxPageNumber, setMaxPageNumber] = useState(1);
  const [currentSelectedPage, setCurrentSelectedPage] = useState(currentSelectedPageNumber ?? 0);
  const [sortedCol, setSortedCol] = useState<SortingCol>(initialSorting);

  useEffect(() => {
    setMaxPageNumber(Math.ceil(maxData / rowsPerPage));
  }, [maxData]);

  useEffect(() => {
    if (currentSelectedPageNumber !== undefined && currentSelectedPageNumber !== currentSelectedPage)
      setCurrentSelectedPage(currentSelectedPageNumber);
  }, [currentSelectedPageNumber]);

  const handleChangePage = useCallback(
    (isNextPressed = false) => {
      setCurrentSelectedPage((pre) => {
        let currentPageNumber = 0;
        if (isNextPressed) currentPageNumber = pre + 1;
        else currentPageNumber = pre - 1 < 0 ? 0 : pre - 1;
        if (onPageChange) onPageChange({ currentPageNumber, rowsPerPage });
        return currentPageNumber;
      });
    },
    [onPageChange],
  );

  const getSortingTitle = (isSorted: boolean, type?: 'asc' | 'desc') => {
    if (!isSorted) return 'Sort in ascending order';
    if (type === 'asc') return 'Sorted in ascending order';
    return 'Sorted in descending order';
  };

  const getTableHeadingRow = () => {
    const tableHeader: JSX.Element[] = [];
    config.forEach((header, index) => {
      if (header.hide) return false;
      const { columnLabel, align, onSort } = header;
      const isSorted = sortedCol?.colIndex === index;
      tableHeader.push(
        <TableCell key={`header${columnLabel}`} className={classes.header} align={align}>
          {onSort === undefined ? (
            columnLabel
          ) : (
            <TableSortLabel
              active={isSorted}
              direction={isSorted ? sortedCol?.direction : 'asc'}
              onClick={() => {
                if (onSort) {
                  const dir = isSorted && sortedCol?.direction === 'asc' ? 'desc' : 'asc';
                  const direction = onSort(dir);
                  // this is done, if on some condition we don't want to sort the column
                  if (direction === null) return;
                  setSortedCol({
                    colIndex: index,
                    direction: typeof direction === 'string' ? direction : dir,
                  });
                }
              }}
            >
              {columnLabel}
            </TableSortLabel>
          )}
        </TableCell>,
      );
    });
    return tableHeader;
  };

  const getTableBody = () => {
    if (!isLoading && Array.isArray(data) && data.length > 0)
      return (
        <TableBody>
          {data.map((rowData, index) => {
            return (
              <TableRow hover tabIndex={-1} key={`row${rowData[keyExtractor]}-${index}`}>
                <CustomTableRow key={`${rowData[keyExtractor]}`} rowData={rowData} rowIndex={index} config={config} />
              </TableRow>
            );
          })}
        </TableBody>
      );
    return null;
  };

  const isBackDisabled = useCallback(() => {
    return currentSelectedPage === 0;
  }, [currentSelectedPage]);

  const isNextDisabled = useCallback(() => {
    // Table data is empty
    if (!isLoading && !(Array.isArray(data) && data.length > 0)) return true;
    // Is last page
    if (currentSelectedPage === maxPageNumber - 1) return true;
    return false;
  }, [currentSelectedPage, data, isLoading, maxPageNumber]);

  const loadingBody = () => <Typography>Loading</Typography>;

  const noDataBody = () => (
    <Box className={classes.row}>
      <img src={NotFound} className={classes.img} />
      <Typography>{noDataText ?? 'There are no records to display'}</Typography>
    </Box>
  );

  const errorBody = () => (
    <Box className={classes.row}>
      <img src={Error} className={classes.img} />
      <Typography variant="body1">{`Error: ${
        typeof errorMsg === 'string' ? errorMsg : 'Unable to find data'
      }`}</Typography>
    </Box>
  );

  const fullWidthTableBody = () => {
    let ui;
    if (isLoading) ui = loadingBody();
    else if (Array.isArray(data) && data.length > 0) return null;
    else if (errorMsg) ui = errorBody();
    else if (Array.isArray(data)) ui = noDataBody();

    return (
      <Box
        className={[variant === 'mini' ? classes.minTableHeight : classes.fullTableHeight, classes.tableBody].join(' ')}
      >
        {ui}
      </Box>
    );
  };

  const tablePagination = () => {
    if (!data || data.length === 0) return null;
    if (variant === 'mini')
      return (
        <Box className={classes.miniPagination} onClick={() => handleChangePage(true)}>
          <Typography align="center" className={classes.showMore}>
            {incrementalRowText || 'Show More'}
          </Typography>
        </Box>
      );
    return (
      <Box width={1} className={classes.tableFooter}>
        <AncillaryButton disabled={isBackDisabled()} onClick={() => handleChangePage(false)}>
          BACK
        </AncillaryButton>
        {maxPageNumber > 0 && <Typography>{`${currentSelectedPage + 1} of ${maxPageNumber}`}</Typography>}
        <AncillaryButton disabled={isNextDisabled()} onClick={() => handleChangePage(true)}>
          NEXT
        </AncillaryButton>
      </Box>
    );
  };

  return (
    <>
      <TableFilters filters={filters} currentPageNumber={currentSelectedPage} rowsPerPage={rowsPerPage} />
      <TableContainer className={[classes.tableContainer, variant === 'mini' && classes.minTableContainer].join(' ')}>
        <Table
          className={classes.table}
          style={{
            minWidth: variant === 'mini' ? 'auto' : '700px',
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>{getTableHeadingRow()}</TableRow>
          </TableHead>
          {getTableBody()}
        </Table>
        {fullWidthTableBody()}
        {!isLoading && tablePagination()}
      </TableContainer>
    </>
  );
};

export default CustomTable;
