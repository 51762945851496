import { Typography, makeStyles, Theme, Link, AncillaryButton } from '@c2fo/react-components';
import { useHistory } from 'react-router-dom';

import { dateFormatter } from '../../../util/util';
import CurrencyFormatter from '../../../util/CurrencyFormatter';

import { CellProps, OnPageChangeParams } from '../../../types/customTable.schema';

import CustomTable from '../../Atoms/CustomTable/CustomTable';

import { tableCol } from '../../../constants/ColorPalette';
import { SellersSortingCol, SortingDirection } from '../../../types/Common';
import { defaultRowPerPage, PaginationDetailsProps } from '../../../constants/Constants';
import { Routes } from '../../../constants/RouterLink';

const useStyles = makeStyles((theme: Theme) => ({
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  amountColTitle: {
    color: tableCol,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  chipCell: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
  },
  sellerIdBtn: {
    fontWeight: 'normal',
    fontSize: 14,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const SellersTable = ({
  data,
  dataCount,
  dataLoading,
  dataError,
  pageInfo,
  pageNumber,
  setPaginationDetails,
  setSortCol,
  setSortOrder,
}: any) => {
  const classes = useStyles({});
  const history = useHistory();

  const dateFormattedCell = ({ rowData }: CellProps, columnKey: any) => {
    return dateFormatter(Date.parse(rowData[columnKey]), 'MMM. dd, yyyy');
  };

  const moveToNextPage = () => {
    setPaginationDetails((prev: PaginationDetailsProps) => ({
      pageNumber: prev.pageNumber + 1,
      pagination: {
        after: pageInfo.endCursor,
        first: defaultRowPerPage,
      },
    }));
  };

  const moveToPreviousPage = () => {
    setPaginationDetails((prev: PaginationDetailsProps) => ({
      pageNumber: prev.pageNumber - 1,
      pagination: {
        before: pageInfo.startCursor,
        last: defaultRowPerPage,
      },
    }));
  };

  const handlePageChange = ({ currentPageNumber }: OnPageChangeParams) => {
    if (currentPageNumber > pageNumber) {
      moveToNextPage();
    } else if (currentPageNumber < pageNumber) {
      moveToPreviousPage();
    }
  };

  const handleSorting = (direction: SortingDirection, sortingKey: SellersSortingCol) => {
    setSortCol(sortingKey);
    setSortOrder(direction.toLocaleUpperCase());
  };

  const sellerIdCell = ({ rowData }: CellProps) => {
    return (
      <AncillaryButton
        className={classes.sellerIdBtn}
        onClick={() => {
          localStorage.setItem('sellerName', rowData?.name || null);
          localStorage.setItem('seller', rowData.identifier);
          history.push(Routes.Authorisation);
        }}
      >
        {rowData.identifier}
      </AncillaryButton>
    );
  };

  const creditLimit = ({ rowData }: CellProps) => {
    return (
      <Typography className={classes.textLeft}>
        {CurrencyFormatter({ amount: rowData.bankDetails[0]?.creditLimit, currency: 'INR' })}
      </Typography>
    );
  };

  return (
    <CustomTable
      data={data}
      currentSelectedPageNumber={pageNumber}
      onPageChange={handlePageChange}
      config={[
        {
          columnLabel: 'Seller ID',
          cell: (row) => sellerIdCell(row),
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'identifier'),
        },
        {
          columnLabel: 'Name',
          selectorKey: 'name',
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'name'),
        },

        {
          columnLabel: 'Credit Limit',
          cell: (row) => creditLimit(row),
        },
      ]}
      keyExtractor={'identifier'}
      errorMsg={!!dataError}
      maxData={dataCount ?? 0}
      isLoading={dataLoading}
    />
  );
};
