import React, { memo, useState } from 'react';
import {
  Typography,
  makeStyles,
  Theme,
  Chip,
  IconButton,
  AngleDownIcon,
  AngleUpIcon,
  Box,
  Collapse,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
} from '@c2fo/react-components';
import { tableCol } from '../../../constants/ColorPalette';

import { dateFormatter } from '../../../util/util';
import CurrencyFormatter from '../../../util/CurrencyFormatter';

const useStyles = makeStyles((theme: Theme) => ({
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  amountColTitle: {
    color: tableCol,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  chipCell: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
const InvoiceTableRow = ({ rowData, rowIndex, setSortCol, setSortOrder, invoiceStatuses }: any) => {
  const classes = useStyles({});
  const [showCollapsedData, setShowCollapsedData] = useState(false);
  // const [updatedRowDataVariable, setUpdatedRowDataVariable] = useState<Record<string, unknown>>({});

  const dateFormattedCell = (data: any) => {
    return dateFormatter(Date.parse(data), 'MMM. dd, yyyy');
  };

  const netAmntPayableCell = () => {
    const paymentAmount = rowData.paymentAmount ?? 0;
    const discount = rowData.discount ?? 0;
    return (
      <Typography className={classes.textLeft}>
        {CurrencyFormatter({ amount: paymentAmount - discount, currency: 'INR' })}
      </Typography>
    );
  };

  const payDate = () => {
    return (
      <Typography className={classes.textLeft}>
        {dateFormatter(Date.parse(rowData.bankAggregation?.invoiceDate), 'MMM. dd, yyyy')}
      </Typography>
    );
  };

  const amountFormattedCell = (columnKey: string) => {
    return (
      <Typography className={classes.textLeft}>
        {CurrencyFormatter({ amount: rowData[columnKey], currency: 'INR' })}
      </Typography>
    );
  };

  const utrNo = () => {
    return <Typography className={classes.textLeft}>{rowData.bankAggregation?.utrNo ?? '--'}</Typography>;
  };

  const reverseDisplayStatus = (invoiceStatus: string) => {
    const status = Object.entries(invoiceStatuses).filter((item: any) => {
      return item[1].indexOf(invoiceStatus) > -1 && item[0] !== 'All';
    });
    return status.flat();
  };

  const statusCell = () => {
    return <Chip label={`${reverseDisplayStatus(rowData.status)[0]}`} color="secondary" />;
  };

  return (
    <>
      <TableRow
        className="jss101"
        style={{ backgroundColor: Math.abs(rowIndex % 2) === 0 ? '#f6f6f6' : '#ffffff' }}
        hover
        tabIndex={-1}
        key={`row${rowData.invoiceNum}-${rowIndex}`}
      >
        <TableCell align={'left'} key={0}>
          {rowData.invoiceNum}
        </TableCell>
        <TableCell align={'left'} key={1}>
          {dateFormattedCell(rowData.invoiceDate)}
        </TableCell>
        <TableCell align={'left'} key={2}>
          {dateFormattedCell(rowData.dueDate)}
        </TableCell>
        <TableCell align={'left'} key={3}>
          {amountFormattedCell('invoiceAmount')}
        </TableCell>
        <TableCell align={'left'} key={4}>
          {amountFormattedCell('paymentAmount')}
        </TableCell>
        <TableCell align={'left'} key={5}>
          {rowData.discountPercentage}
        </TableCell>
        <TableCell align={'left'} key={6}>
          {rowData.dpe ?? '--'}
        </TableCell>
        <TableCell align={'left'} key={7}>
          {amountFormattedCell('discount')}
        </TableCell>
        <TableCell align={'left'}>{netAmntPayableCell()}</TableCell>
        <TableCell align={'left'}>{statusCell()}</TableCell>
        <TableCell align={'left'}>
          <IconButton
            onClick={() => {
              setShowCollapsedData(!showCollapsedData);
            }}
          >
            {showCollapsedData ? <AngleUpIcon /> : <AngleDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow hover tabIndex={-1} key={`row${rowData.invoiceNum}-${rowIndex}`}>
        <TableCell colSpan={11} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={showCollapsedData} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                <TableRow hover tabIndex={-1} key={`row${rowData.invoiceNum}-${rowIndex}`}>
                  <TableCell width={'15%'} align={'left'} key={0} style={{ fontWeight: 'bold' }}>
                    Payment Date
                  </TableCell>
                  <TableCell width={'85%'} align={'left'} key={0}>
                    {payDate()}
                  </TableCell>
                </TableRow>
                <TableRow hover tabIndex={-1} key={`row${rowData.invoiceNum}-${rowIndex}`}>
                  <TableCell width={'15%'} align={'left'} key={0} style={{ fontWeight: 'bold' }}>
                    UTR No
                  </TableCell>
                  <TableCell width={'85%'} align={'left'} key={0}>
                    {utrNo()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default InvoiceTableRow;
