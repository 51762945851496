import { makeStyles, Theme, Liquidity } from '@c2fo/react-components';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    paddingBottom: theme.spacing(1),
  },
  tableContainer: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  minTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    // 75px is the height of the header
    minHeight: 'calc(100% - 75px)',
    justifyContent: 'space-between',
  },
  tableFooter: {
    flex: 1,
    padding: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  minTableHeight: {
    minHeight: '20rem',
  },
  fullTableHeight: {
    minHeight: '32rem',
  },
  tableBody: {
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
  },
  img: {
    height: '103px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  showMore: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: Liquidity.colors.supporting.deepBlue,
  },
  header: {
    color: '#11305D',
    fontWeight: 'bold',
  },
  miniPagination: {
    padding: '8px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  amountColTitle: {
    // color: tableCol,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  chipCell: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
  },
}));

export default useStyles;
