export enum COMPONENTS {
  INACTIVITY_MONITOR = 'inactivity',
}

export enum TRACK_EVENTS {
  CLICKED = 'clicked',
  CLOSED = 'closed',
  COMPLETE = 'complete',
  ERROR = 'error',
  INVALID_DATA = 'invalid-data',
  LOADED = 'loaded',
  LOGOUT = 'logout',
  MODAL_SHOWN = 'modal-shown',
  OPENED = 'opened',
  OPT_IN = 'opt-in',
  OPT_OUT = 'opt-out',
  START = 'start',
  SUBMITTED = 'submitted',
  SUBMISSION_TIMEOUT = 'submission-timeout',
  SUCCESS = 'success',
  UPDATED = 'updated',
  WITHDRAWN = 'withdrawn',
}

export interface Error {
  error: string;
}
