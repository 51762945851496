import { useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/client';
import { Liquidity, Paper, makeStyles, Theme, Typography, Container, CTAButton, Alert } from '@c2fo/react-components';
import { Redirect, useHistory } from 'react-router-dom';

import { AlertData, AlertType } from '../../types/Common';

import { GET_USER_ACCOUNT_DETAILS, UPDATE_USER_ACCOUNT } from '../../schemas/User.schema';

import LoadingScreen from '../../components/Atoms/LoadingScreen/LoadingScreen';
import { Navigation } from '../../components/Molecules/Navigation/Navigation';
import { Routes } from '../../constants/RouterLink';
import { TncData } from './data';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    backgroundColor: Liquidity.colors.text.inverse,
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  tncCard: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    height: '100%',
    position: 'relative',
  },
  tncCardHeader: {
    padding: theme.spacing(2.5),
    width: '100%',
    borderBottom: '1px solid rgb(224, 224, 224)',
    borderBottomColor: 'rgb(224, 224, 224)',
  },
  tncCardHeaderTitle: {
    marginBottom: 0,
    color: 'rgb(76,76,76)',
    fontWeight: 'bold',
    fontSize: 17,
  },
  tncCardPara: {
    color: 'rgb(76,76,76)',
    fontSize: 15,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  tncCardBody: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(5),
  },
  spacer: {
    height: theme.spacing(5),
  },
  forgotLink: {
    marginTop: 20,
    color: 'rgb(0, 93, 131)',
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: 14,
  },
  mainContainer: {
    width: '98%',
  },
}));

export const TermsAndConditionsPage = () => {
  const [alertData, setAlertData] = useState<AlertData>();
  const classes = useStyles({});
  const history = useHistory();

  const { loading: userLoading, error: userError, data: userData } = useQuery(GET_USER_ACCOUNT_DETAILS, {
    variables: {
      fetchPolicy: 'cache-and-network',
    },
  });
  const [updateUser, { error: updateUserError }] = useMutation(UPDATE_USER_ACCOUNT);

  useEffect(() => {
    if (!(userError || updateUserError)) return;
    setAlertData({
      message: 'Something went wrong',
      showAlert: true,
      type: AlertType.ERROR,
    });
  }, [userError, updateUserError]);

  if (userData?.getSellerAccountDetails?.efiAuditor) {
    return <Redirect to={{ pathname: Routes.Sellers }} />;
  }
  if (userData?.getSellerAccountDetails?.tncAccepted) {
    return <Redirect to={{ pathname: Routes.Authorisation }} />;
  }

  return (
    <div className={classes.wrapper}>
      <Navigation activeItem="" user={{ userLoading, userData }} canShowNavItems={false} />

      <Container maxWidth="xl" className={classes.mainContainer}>
        {userLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <Paper className={classes.tncCard}>
              <div className={classes.tncCardHeader}>
                <Typography className={classes.tncCardHeaderTitle} variant="h6">
                  TERMS AND CONDITIONS
                </Typography>
              </div>
              <div className={classes.tncCardBody}>
                <div className={classes.tncCardPara}>
                  <TncData />
                </div>
                <div className={classes.spacer} />
                <CTAButton
                  onClick={() => {
                    updateUser({ variables: { email: userData?.getSellerAccountDetails?.email } });
                    history.push(Routes.Authorisation);
                  }}
                  disabled={userLoading}
                  size="medium"
                  style={{ alignSelf: 'flex-end' }}
                >
                  ACCEPT
                </CTAButton>
              </div>
            </Paper>
          </>
        )}
        {alertData && (
          <Alert
            variant={alertData.type}
            open={alertData.showAlert}
            onClose={() => setAlertData(undefined)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            {alertData?.message}
          </Alert>
        )}
        <div className={classes.spacer} />
      </Container>
    </div>
  );
};
