import { HttpService, TokenService, Token } from '@c2fo/react-services';
import { legacyC2foApiConfig, env, userAuthServiceConfig } from '@c2fo/common-config';
import { EventsService } from '../EventsService/EventsService';
import { Routes } from '../../constants/RouterLink';
import { config } from '../../../config/config';

interface TokenResponse {
  jwt: string;
  payload: Token.Payload;
}

// TODO: Add support for url params

export class AuthService {
  constructor(
    private tokenService: TokenService,
    private httpService: HttpService,
    private eventsService: EventsService,
  ) {}

  static LEGACY_UI_TOKEN_PARAM = 'authToken';

  isAuthenticated(): boolean {
    return !this.tokenService.isTokenExpired();
  }

  async refreshToken(): Promise<void> {
    const response = await this.httpService.post<TokenResponse>(
      `${legacyC2foApiConfig.LEGACY_C2FO_API_URL}/refresh-token`,
    );
    this.tokenService.setToken(response.data.jwt);
  }

  async logout(): Promise<void> {
    await fetch(config.C2FO_APP_LOGOUT_URL, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ token: this.tokenService.getToken() }),
    }).then(() => {
      this.tokenService.clearToken('logout');
      window.location.replace(`${Routes.Login}`);
    });
  }

  async deleteToken(token: string): Promise<void> {
    const authServiceUrl = env.getString('GATEWAY_URL')
      ? `${env.getString('GATEWAY_URL')}/api/auth/c2fo-user-auth/${token}`
      : `${userAuthServiceConfig.USER_AUTH_SERVICE_URL}/c2fo-user-auth/${token}`;

    await this.httpService.delete<TokenResponse>(authServiceUrl);
  }
}
