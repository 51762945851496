/* eslint-disable max-lines */
import { useState, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  Paper,
  Typography,
  Container,
  AngleDownIcon,
  FilterIcon,
  DownloadIcon,
  Grid,
  Divider,
  Box,
  SearchBar,
  ButtonDropdown,
  ButtonDropdownItem,
  Alert,
  AncillaryButton,
} from '@c2fo/react-components';

import { CSVLink } from 'react-csv';

import { INVOICES, INVOICE_AGGREGATE, GET_ALL_VENDORS } from '../../schemas/Invoice.schema';
import { GET_USER_ACCOUNT_DETAILS } from '../../schemas/User.schema';

import { AlertData, AlertType } from '../../types/Common';

import LoadingScreen from '../../components/Atoms/LoadingScreen/LoadingScreen';
import { Navigation } from '../../components/Molecules/Navigation/Navigation';

import { dateFormatter } from '../../util/util';
import CurrencyFormatter from '../../util/CurrencyFormatter';
import {
  defaultRowPerPage,
  InvoicesCsvHeaders,
  maxPerPageAllowedValue,
  PaginationDetailsProps,
} from '../../constants/Constants';

import { Routes } from '../../constants/RouterLink';
import useStyles from './styles';

import { InvoiceTable } from '../../components/Molecules/InvoiceTable/InvoiceTable';
import { InvoicesSummary } from '../../components/Molecules/InvoicesSummary/InvoicesSummary';
import { VendorsModal } from '../../components/Molecules/VendorsModal/VendorsModal';
import { OnPageChangeParams } from '../../types/customTable.schema';
import { SellerName } from '../../components/Molecules/SellerName/SellerName';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint max-statements: ["error", 50] */
/* eslint complexity: ["error", 25] */
export const InvoicePage = () => {
  const history = useHistory();

  const INVOICES_STATUS_MAPING = {
    All: 'NEW,SELLER ACCEPTED,SENT,ACCEPTED,PROCESSED,PARTIAL PROCESSED,SETTLED,PARTIALLY SETTLED',
    New: 'NEW',
    Authorised: 'SELLER ACCEPTED,SENT,ACCEPTED',
    Financed: 'PROCESSED,PARTIAL PROCESSED',
    Repaid: 'SETTLED',
    'Partially Repaid': 'PARTIALLY SETTLED',
    'Rejected by Bank': 'BANK REJECTED',
  };

  const filterTimeOptions = ['All', '30 Days', '60 Days', '90 Days'];

  const [alertData, setAlertData] = useState<AlertData>();

  const urlQuery = new URLSearchParams(window.location.search);
  const [search, setSearch] = useState(urlQuery.get('query') ?? '');
  const [invStatus, setInvStatus] = useState(INVOICES_STATUS_MAPING.All);
  const [time, setTime] = useState('All');

  const [supplier, setSupplier] = useState(urlQuery.get('supplier') ?? 'All');
  const [sortCol, setSortCol] = useState('invoiceAmount');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [showModal, setShowModal] = useState(false);
  const [{ pageNumber, pagination }, setPaginationDetails] = useState<PaginationDetailsProps>({
    pageNumber: 0,

    pagination: {
      after: '',
      first: defaultRowPerPage,
    },
  });

  const classes = useStyles({});

  const commonInvoicesVariables = () => {
    const filters = {
      paging: { first: maxPerPageAllowedValue, after: '' },
      orgId: localStorage.seller ?? userData?.getSellerAccountDetails?.identifier,
      fetchPolicy: 'cache-and-network',
      invoiceDate: '2022-08-01',
      status: invStatus.split(','),
      vendorNum: supplier !== 'All' ? supplier : '%',
      vendorName: search.length > 0 ? `%${search}%` : '%',
      invoiceNum: search.length > 0 ? `%${search}%` : '%',
      sortOrder,
      sortCol,
    };

    if (time !== 'All') {
      const date = new Date();
      date.setDate(date.getDate() - parseInt(time.replace(' Days', ''), 10));
      filters.invoiceDate = dateFormatter(date.toDateString(), 'yyyy-MM-dd');
    }
    return filters;
  };

  const getInvoicesVariables = () => {
    const filters = {
      ...commonInvoicesVariables(),
      paging: pagination,
    };
    return filters;
  };

  const { loading: userLoading, data: userData, error: userError } = useQuery(GET_USER_ACCOUNT_DETAILS, {
    variables: {
      fetchPolicy: 'cache-and-network',
    },
  });

  const { loading: allVendorsLoading, error: allVendorsError, data: allVendorsData } = useQuery(GET_ALL_VENDORS, {
    variables: userData && {
      sellerId: localStorage.seller ?? userData?.getSellerAccountDetails?.identifier,
    },
    skip: !userData,
  });

  const { loading: invoicesLoading, error: invoicesError, data: invoicesData } = useQuery(INVOICES, {
    variables: userData && getInvoicesVariables(),
    skip: !userData,
  });

  const { loading: invoicesCsvLoading, error: invoicesCsvError, data: invoicesCsvData } = useQuery(INVOICES, {
    variables: userData && commonInvoicesVariables(),
    skip: !userData,
  });

  const { loading: invoicesAggregateLoading, data: invoicesAggregateData, error: invoicesAggregateError } = useQuery(
    INVOICE_AGGREGATE,
    {
      variables: userData && getInvoicesVariables(),
      skip: !userData,
    },
  );

  const dataForTable = useMemo(() => {
    return invoicesData?.invoices?.edges?.map((item: any) => item.node);
  }, [invoicesData]);

  const dataForCsv = useMemo(() => {
    return invoicesCsvData?.invoices?.edges?.map((item: any) => item.node);
  }, [invoicesCsvData]);

  const allVendors = useMemo(() => {
    let data = [{ __typename: 'Vendor', vendorName: 'All', vendorNum: 'All' }];
    data = data.concat(allVendorsData?.getAllVendors?.data ?? []);
    return data;
  }, [allVendorsData]);

  const supplierName = () => {
    return allVendors.filter((item) => item?.vendorNum === supplier)[0]?.vendorName;
  };

  useEffect(() => {
    if (!(invoicesAggregateError || userError || allVendorsError)) return;
    setAlertData({
      message: 'Something went wrong',
      showAlert: true,
      type: AlertType.ERROR,
    });
  }, [invoicesAggregateError, userError, allVendorsError]);

  const reverseDisplayStatus = (invoiceStatus: string) => {
    return Object.entries(INVOICES_STATUS_MAPING)
      .filter((item: any) => {
        return item[1].indexOf(invoiceStatus) > -1 && item[0] !== 'All';
      })
      .flat();
  };

  const showLoading = () => {
    if (userLoading || allVendorsLoading) return <LoadingScreen />;
  };

  const dataForCSV = () => {
    const data = (dataForCsv ?? []).map((row: any) => {
      const paymentAmount = row.paymentAmount ?? 0;
      const discount = row.discount ?? 0;

      const status = Object.entries(INVOICES_STATUS_MAPING).filter((item: any) => {
        return item[1].indexOf(row.status) > -1 && item[0] !== 'All';
      });
      return [
        `${row.invoiceNum}`,
        `${row.vendorName}`,
        dateFormatter(row.invoiceDate, 'yyyy-MM-dd'),
        dateFormatter(row.dueDate, 'yyyy-MM-dd'),
        CurrencyFormatter({ amount: row.invoiceAmount, currency: 'INR' }),
        CurrencyFormatter({ amount: row.paymentAmount, currency: 'INR' }),
        row.discountPercentage,
        CurrencyFormatter({ amount: row.discount, currency: 'INR' }),
        CurrencyFormatter({ amount: paymentAmount - discount, currency: 'INR' }),
        status.flat()[0],
        `${row.bankAggregation?.utrNo}`,
        dateFormatter(row.bankAggregation?.invoiceDate, 'yyyy-MM-dd'),
        `${row.bankAggregation?.dpe}`,
      ];
    });
    return data;
  };

  const moveToNextPage = () => {
    setPaginationDetails((prev: PaginationDetailsProps) => ({
      pageNumber: prev.pageNumber + 1,
      pagination: {
        after: invoicesData?.invoices?.pageInfo.endCursor,
        first: defaultRowPerPage,
      },
    }));
  };

  const moveToPreviousPage = () => {
    setPaginationDetails((prev: PaginationDetailsProps) => ({
      pageNumber: prev.pageNumber - 1,
      pagination: {
        before: invoicesData?.invoices?.pageInfo.startCursor,
        last: defaultRowPerPage,
      },
    }));
  };

  const handlePageChange = ({ currentPageNumber }: OnPageChangeParams) => {
    if (currentPageNumber > pageNumber) {
      moveToNextPage();
    } else if (currentPageNumber < pageNumber) {
      moveToPreviousPage();
    }
  };

  if (!userLoading && !userData?.getSellerAccountDetails?.efiAuditor) {
    if (!userData?.getSellerAccountDetails?.tncAccepted) {
      return <Redirect to={{ pathname: Routes.Tnc }} />;
    }
  }

  if (!userLoading && userData?.getSellerAccountDetails?.efiAuditor) {
    if (!localStorage.seller) {
      return <Redirect to={{ pathname: Routes.Sellers }} />;
    }
  }

  return (
    <div className={classes.wrapper}>
      <Navigation activeItem={'Invoices'} user={{ userData, userLoading }} />
      {showLoading() || (
        <>
          <Paper elevation={0}>
            <Container maxWidth="xl" data-testid="auth-summary" className={classes.mainContainer}>
              <SellerName isEfiAuditor={userData?.getSellerAccountDetails?.efiAuditor} />
              <InvoicesSummary data={invoicesAggregateData} dataLoading={invoicesAggregateLoading} />
            </Container>
            <div className={classes.spacer} />
            <Divider />
            <Container maxWidth="xl" className={classes.mainContainer}>
              <Box className={classes.card}>
                <div className={classes.invoicesTableHeader}>
                  <Grid container spacing={0}>
                    <Grid item sm={2} xs={12}>
                      <Box className={classes.filterBarTitleBox}>
                        <Typography className={classes.cardHeaderTitle} variant="h6">
                          INVOICES
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Box className={classes.invoicesFilter}>
                        <FilterIcon className={classes.filterBarIcon} style={{ color: 'rgb(156, 154, 154)' }} />
                        <Box className={classes.filterBox}>
                          <Typography>Supplier:</Typography>
                          <AncillaryButton
                            className={classes.dropdownFilterBtn}
                            endIcon={<AngleDownIcon />}
                            onClick={() => {
                              setShowModal(true);
                            }}
                            style={{ textTransform: 'capitalize' }}
                          >
                            {supplierName() ?? 'All'}
                          </AncillaryButton>
                        </Box>
                        <Box className={classes.filterBox}>
                          <Typography>Status:</Typography>
                          <ButtonDropdown
                            className={classes.dropdownFilterBtn}
                            label={reverseDisplayStatus(invStatus)[0] ?? 'All'}
                            placement={'bottom'}
                            size={'small'}
                            endIcon={<AngleDownIcon />}
                          >
                            {Object.entries(INVOICES_STATUS_MAPING).map((item: any, key: any) => {
                              return (
                                <ButtonDropdownItem key={key} onClick={() => setInvStatus(item[1])}>
                                  <Typography>{item[0]}</Typography>
                                </ButtonDropdownItem>
                              );
                            })}
                          </ButtonDropdown>
                        </Box>
                        <Box className={classes.filterBox}>
                          <Typography>Time:</Typography>
                          <ButtonDropdown
                            className={classes.dropdownFilterBtn}
                            label={time}
                            placement={'bottom'}
                            size={'small'}
                            endIcon={<AngleDownIcon />}
                          >
                            {filterTimeOptions.map((duration) => (
                              <ButtonDropdownItem key={duration} onClick={() => setTime(duration)}>
                                <Typography>{duration}</Typography>
                              </ButtonDropdownItem>
                            ))}
                          </ButtonDropdown>
                        </Box>
                        <Box className={classes.searchBar}>
                          <SearchBar
                            value={search}
                            placeholder="Search Invoices"
                            onChange={(text) => {
                              setSearch(text);
                            }}
                            onSubmit={(text) => {
                              setSearch(text);
                            }}
                            onClear={() => {
                              setSearch('');
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid sm={1} xs={12}>
                      <Box className={classes.downloadIconBox}>
                        <CSVLink data={[InvoicesCsvHeaders].concat(dataForCSV() || [])}>
                          <DownloadIcon className={classes.downloadIcon} />
                        </CSVLink>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <Divider style={{ marginBottom: 15 }} />
                <InvoiceTable
                  config={[]}
                  invoiceStatuses={INVOICES_STATUS_MAPING}
                  data={dataForTable}
                  maxData={invoicesData?.invoices?.totalCount}
                  isLoading={invoicesLoading}
                  errorMsg={!!invoicesError}
                  onPageChange={handlePageChange}
                  pageInfo={invoicesData?.invoices?.pageInfo}
                  currentSelectedPageNumber={search.length > 0 ? 0 : pageNumber}
                  setSortCol={setSortCol}
                  keyExtractor="invoiceNum"
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
              </Box>
            </Container>
            {!allVendorsLoading && (
              <>
                <VendorsModal
                  allVendors={allVendors}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setSupplier={setSupplier}
                />
              </>
            )}
            {alertData && (
              <Alert
                variant={alertData.type}
                open={alertData.showAlert}
                onClose={() => setAlertData(undefined)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                {alertData?.message}
              </Alert>
            )}
            <div className={classes.spacer} />
          </Paper>
        </>
      )}
    </div>
  );
};
