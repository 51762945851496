import { gql } from '@apollo/client';

export const GET_ALL_VENDORS = gql`
  query getAllVendors($sellerId: String!) {
    getAllVendors(input: { sellerId: $sellerId }) {
      data {
        vendorNum
        vendorName
      }
    }
  }
`;

export const INVOICES = gql`
  query invoices(
    $paging: CursorPaging
    $orgId: String!
    $status: [String!]
    $vendorNum: String
    $invoiceDate: DateTime
    $vendorName: String
    $invoiceNum: String
    $sortCol: InvoiceSortFields!
    $sortOrder: SortDirection!
  ) {
    invoices(
      paging: $paging
      filter: {
        orgId: { eq: $orgId }
        aggregationId: { isNot: null }
        status: { in: $status }
        and: [{ or: [{ invoiceNum: { iLike: $invoiceNum } }, { vendorName: { iLike: $vendorName } }] }]
        vendorNum: { iLike: $vendorNum }
        invoiceDate: { gte: $invoiceDate }
      }
      sorting: { field: $sortCol, direction: $sortOrder }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          vendorName
          invoiceNum
          invoiceDate
          dueDate
          invoiceAmount
          paymentAmount
          discount
          dpe
          discountPercentage
          status
          bankAggregation {
            utrNo
            invoiceDate
          }
        }
      }
    }
  }
`;

export const INVOICE_AGGREGATE = gql`
  query invoiceAggregate(
    $orgId: String!
    $status: [String!]
    $vendorNum: String
    $invoiceDate: DateTime
    $vendorName: String
  ) {
    invoiceAggregate(
      filter: {
        aggregationId: { isNot: null }
        orgId: { eq: $orgId }
        status: { in: $status }
        vendorNum: { iLike: $vendorNum }
        vendorName: { iLike: $vendorName }
        invoiceDate: { gte: $invoiceDate }
      }
    ) {
      count {
        invoiceId
      }
      sum {
        paymentAmount
        discount
      }
    }
  }
`;
