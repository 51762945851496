import { useState, useRef, FC } from 'react';
import IdleTimer from 'react-idle-timer';
import { useInterval } from 'react-use';
import { Modal, PrimaryButton, TypeBase } from '@c2fo/react-components';

import { useServices } from '../../../services';

export const InactivityMonitor = () => {
  const monitorRef = useRef<IdleTimer>(null);

  const { tokenService, authenticationService, eventsService } = useServices();
  const [showModal, setShowModal] = useState(false);

  const extendSession = async () => {
    eventsService.trackInactivityMonitorRefreshStart();
    try {
      await authenticationService.refreshToken();
      eventsService.trackInactivityMonitorRefreshSuccess();
    } catch (error) {
      eventsService.trackInactivityMonitorRefreshError({ error: error.message });
      await authenticationService.logout();
    }

    setShowModal(false);
  };

  const displayModalToUser = () => {
    if (!showModal) {
      eventsService.trackInactivityMonitorModalShown();
      setShowModal(true);
    }
  };

  useInterval(async () => {
    const token = tokenService.getTokenContent();
    const now = Math.round(Date.now() / 1000); // in seconds
    if (!token || !monitorRef.current) {
      return;
    }

    // token is expired
    if (token.exp <= now) {
      eventsService.trackInactivityMonitorLogout();
      setShowModal(false);
      await authenticationService.logout();
      return;
    }

    // if token is within 30s of expiring
    if (token.exp - now <= 30) {
      const lastActivity = Math.round(monitorRef.current.getLastActiveTime() / 1000); // in seconds

      if (lastActivity > token.iat) {
        await extendSession();
      } else {
        displayModalToUser();
      }
    }
  }, 1000);

  return (
    <>
      <IdleTimer ref={monitorRef} />
      <Modal
        open={showModal}
        onClose={extendSession}
        modalTitle={'Still There?'}
        actionsContent={
          <PrimaryButton size="large" onClick={extendSession} data-testid="inactivity.modal.button">
            Extend Session
          </PrimaryButton>
        }
      >
        <TypeBase data-testid="inactivity:modal:content">
          We&apos;ve detected that you may be away from your device. For security reasons you will be logged out in 30
          seconds.
        </TypeBase>
      </Modal>
    </>
  );
};
