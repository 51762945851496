import * as React from 'react';
import { Component } from 'react';
import { Liquidity } from '@c2fo/react-components';

export interface CircleProps {
  progress: number;
  progressColor?: string;
  bgColor?: string;
  percentSpacing?: number;
}

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round(((100 - Math.min(val, 100)) / 100) * diameter);

export class CircularProgressWithLabel extends Component<CircleProps> {
  static defaultProps: CircleProps = {
    progress: 0,
    bgColor: Liquidity.colors.supporting.grey25,
  };

  get text() {
    const { progress } = this.props;

    return (
      <text
        style={{ font: 'bold 5rem Helvetica, Arial, sans-serif' }}
        fill={Liquidity.colors.text.standard}
        x={radius}
        y={radius}
        textAnchor="middle"
        dominantBaseline="central"
      >
        {progress}
        {<tspan dx={10}>%</tspan>}
      </text>
    );
  }

  render() {
    const { text } = this;
    const { progress } = this.props;
    const strokeDashoffset = getOffset(progress);
    const transition = undefined;
    const strokeLinecap = 'butt';
    const svgSize = '100';

    return (
      <svg width={svgSize} height={svgSize} viewBox="-25 -25 400 400">
        <circle stroke={Liquidity.colors.supporting.grey25} cx="175" cy="175" r="175" strokeWidth={'35'} fill="none" />
        <circle
          stroke={Liquidity.colors.primary.brightBlue}
          transform="rotate(-90 175 175)"
          cx="175"
          cy="175"
          r="175"
          strokeDasharray="1100"
          strokeWidth={'35'}
          strokeDashoffset="1100"
          strokeLinecap={strokeLinecap}
          fill="none"
          style={{ strokeDashoffset, transition }}
          onTransitionEnd={() => {}}
        />
        {text}
      </svg>
    );
  }
}
