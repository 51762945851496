import { gql } from '@apollo/client';

export const REPAYMENTS = gql`
  query bankAggregations(
    $vendorNum: String
    $dateApproved: DateTime!
    $bank: String
    $status: [String!]
    $sellerId: String!
    $loanRefNumber: String
    $invoiceDate: DateTime!
    $paging: CursorPaging
    $sortCol: bankAggregationSortFields!
    $sortOrder: SortDirection!
  ) {
    bankAggregations(
      filter: {
        vendorNum: { iLike: $vendorNum }
        bank: { iLike: $bank }
        status: { in: $status }
        sellerId: { iLike: $sellerId }
        and: [{ or: [{ loanRefNumber: { iLike: $loanRefNumber } }] }]
        dateApproved: { gte: $dateApproved }
        invoiceDate: { gte: $invoiceDate }
      }
      sorting: { field: $sortCol, direction: $sortOrder }
      paging: $paging
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          vendorNum
          dateApproved
          loanRefNumber
          sellerId
          bank
          approvedInvoiceAmount
          bankDueDate
          invoiceDate
          utrNo
          loanAmountOutstanding
        }
      }
    }
  }
`;

export const REPAYMENTS_SUMMARY = gql`
  query($sellerId: String!) {
    getAllSummary(input: { sellerId: $sellerId }) {
      data {
        bank
        creditLimit
        utilization
      }
      totalCreditLimit
      totalCreditAvailable
      totalLoanUtitlization
    }
  }
`;
