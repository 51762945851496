import {
  makeServices,
  ActiveDirectoryAuthService,
  HttpService,
  TokenService,
  CurrencyService,
  ReportingService,
} from '@c2fo/react-services';
import { userAuthServiceConfig, legacyC2foApiConfig } from '@c2fo/common-config';
import { EventsService } from './EventsService/EventsService';
import { AuthService } from './AuthService/AuthService';
/**
 * Create service singletons.
 */

const tokenService = new TokenService('C2FOAuthToken');
const httpService = new HttpService(tokenService);

const authService = new ActiveDirectoryAuthService(
  tokenService,
  httpService,
  userAuthServiceConfig.USER_AUTH_SERVICE_URL,
);
const currencyService = new CurrencyService();
const reportingService = new ReportingService(
  httpService,
  tokenService,
  'ecom-financing-india',
  legacyC2foApiConfig.LEGACY_C2FO_API_URL,
);
const eventsService = new EventsService(reportingService);
export const authenticationService = new AuthService(tokenService, httpService, eventsService);

export interface Services {
  authService: ActiveDirectoryAuthService;
  tokenService: TokenService;
  currencyService: CurrencyService;
  httpService: HttpService;
  eventsService: EventsService;
  authenticationService: AuthService;
}

export const { ServicesProvider, ServicesContext, useServices } = makeServices<Services>({
  authService,
  authenticationService,
  tokenService,
  currencyService,
  httpService,
  eventsService,
});
