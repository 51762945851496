import { makeStyles, Theme } from '@c2fo/react-components';
import { Redirect, useLocation } from 'react-router-dom';
import { config } from '../../../config/config';
import LoadingScreen from '../../components/Atoms/LoadingScreen/LoadingScreen';
import { Routes } from '../../constants/RouterLink';
import { useServices } from '../../services';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    height: '100vh',
    position: 'relative',
    width: '100%',
  },
  filter: {
    height: '100%',
    left: 0,
    opacity: 0.6,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  container: {
    position: 'absolute',
    zIndex: 1,
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  paper: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(12),
    padding: theme.spacing(5, 4, 8),
    position: 'relative',
    zIndex: 1,
  },
  logo: {
    display: 'inline',
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}));

export function redirectToLoginGateway() {
  const redirectURL = `${config.C2FO_APP_URL}/login?redirect-to=${encodeURIComponent(
    window.location.href,
  )}&tokenQueryParam=authorization`;
  window.location.replace(redirectURL);
}

export const LoginPage = () => {
  const { authService, tokenService } = useServices();
  const classes = useStyles({});
  const query = new URLSearchParams(useLocation().search);

  const authorization = query.get('authorization');
  if (authorization) {
    tokenService.setToken(authorization);
  }

  if (authService.isAuthenticated()) {
    return <Redirect to={Routes.Tnc} />;
  }

  tokenService.clearToken();

  redirectToLoginGateway();

  return (
    <div className={classes.wrapper}>
      <LoadingScreen />
      <div className={classes.filter} />
    </div>
  );
};
