export type SortingDirection = 'asc' | 'desc';

export enum AlertType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

export interface AlertData {
  type: AlertType;
  showAlert: boolean;
  message: string;
}

export type AuthorizationSortingCol =
  | 'vendorName'
  | 'funder'
  | 'invoiceCount'
  | 'amount'
  | 'discount'
  | 'amountPayable'
  | 'paymentDate'
  | 'discountPercent';

export type InvoicesSortingCol =
  | 'invoiceNum'
  | 'invoiceDate'
  | 'dueDate'
  | 'invoiceAmount'
  | 'paymentAmount'
  | 'discountPercentage'
  | 'discount'
  | 'status';

export type RepaymentsSortingCol =
  | 'loanRefNumber'
  | 'sellerId'
  | 'dateApproved'
  | 'approvedInvoiceAmount'
  | 'bankDueDate'
  | 'loanAmountOutstanding'
  | 'bank'
  | 'vendorNum'
  | 'invoiceDate'
  | 'dpe'
  | 'utrNo';

export type SellersSortingCol = 'identifier' | 'name' | 'email';
