import { makeStyles, Theme, Liquidity } from '@c2fo/react-components';
import { tableCol } from '../../constants/ColorPalette';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    flex: 1,
    height: '100vh',
    backgroundColor: Liquidity.colors.text.inverse,
    position: 'relative',
    width: '100%',
  },
  card: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    position: 'relative',
    boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.2)',
  },
  cardHeader: {
    padding: theme.spacing(2.5),
    width: '100%',
    borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
  },
  cardHeaderTitleRow: {
    height: 25,
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderTitle: {
    marginBottom: 0,
    paddingLeft: theme.spacing(2.5),
    color: Liquidity.colors.text.standard,
    fontWeight: 'bold',
    fontSize: 17,
  },
  cardHeaderSubtitleRow: {
    height: 35,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderSubtitle: {
    fontSize: 13,
    color: Liquidity.colors.text.standardLight,
  },

  spacer: {
    height: theme.spacing(5),
  },

  mainContainer: {
    width: '98%',
  },
  textRight: {
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  repaymentsTableHeader: {
    height: theme.spacing(9),
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    borderBottomColor: Liquidity.colors.misc.formDisabled,
  },

  repaymentsFilter: {
    backgroundColor: Liquidity.colors.supporting.grey10,
    height: theme.spacing(9),
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.6),
    paddingRight: theme.spacing(1.6),
    justifyContent: 'space-between',
  },
  searchBar: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  filterBox: {
    backgroundColor: Liquidity.colors.text.inverse,
    alignItems: 'center',
    paddingLeft: 10,
    borderRadius: 5,
    height: 43,
    flexDirection: 'row',
    display: 'flex',
  },
  dropdownFilterBtn: {
    backgroundColor: Liquidity.colors.text.inverse,
    textTransform: 'capitalize',
    color: Liquidity.colors.text.standard,
    fontSize: 15,
    '&:hover': {
      backgroundColor: Liquidity.colors.text.inverse,
    },
  },
  filterText: {
    border: '0px',
    height: 30,
    fontWeight: 'bold',
    fontSize: 15,
    marginLeft: 10,
    marginRight: 10,
    '&:focus-visible': {
      outline: '0px',
    },
  },
  filterBarIcon: {
    height: theme.spacing(6),
  },
  downloadIcon: {
    color: Liquidity.colors.text.standard,
  },
  downloadIconBox: {
    height: theme.spacing(9),

    backgroundColor: Liquidity.colors.text.inverse,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  filterBarTitleBox: {
    height: theme.spacing(9),
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
