import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { makeStyles, Liquidity, Theme, Alert } from '@c2fo/react-components';
import { GET_USER_ACCOUNT_DETAILS } from '../../schemas/User.schema';

import { AlertData, AlertType } from '../../types/Common';

import { Navigation } from '../../components/Molecules/Navigation/Navigation';
import { Authorization } from '../../components/Organism/Authorization/Authorization';
import LoadingScreen from '../../components/Atoms/LoadingScreen/LoadingScreen';
import { Routes } from '../../constants/RouterLink';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    color: 'white',
    flex: 1,
    height: '100vh',
    backgroundColor: Liquidity.colors.text.inverse,
    position: 'relative',
    width: '100%',
  },
}));

export const AuthorizationPage = () => {
  const [alertData, setAlertData] = useState<AlertData>();
  const classes = useStyles({});

  const { loading: userLoading, error: userError, data: userData } = useQuery(GET_USER_ACCOUNT_DETAILS, {
    variables: {
      fetchPolicy: 'cache-and-network',
    },
  });

  useEffect(() => {
    if (!userError) return;
    setAlertData({
      message: 'Something went wrong',
      showAlert: true,
      type: AlertType.ERROR,
    });
  }, [userError]);
  if (!userLoading && !userData?.getSellerAccountDetails?.efiAuditor) {
    if (!userData?.getSellerAccountDetails?.tncAccepted) {
      return <Redirect to={{ pathname: Routes.Tnc }} />;
    }
  }

  return (
    <div className={classes.wrapper}>
      <Navigation activeItem="Authorisation" user={{ userLoading, userData }} />
      {!userLoading ? <Authorization user={{ userLoading, userData }} /> : <LoadingScreen />}
      {alertData && (
        <Alert
          variant={alertData.type}
          open={alertData.showAlert}
          onClose={() => setAlertData(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {alertData?.message}
        </Alert>
      )}
    </div>
  );
};
