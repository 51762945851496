import { useState } from 'react';
import { Box, Typography, makeStyles, Theme, SearchBar, Modal, ButtonDropdownItem } from '@c2fo/react-components';

const useStyles = makeStyles((theme: Theme) => ({
  spacer: {
    height: theme.spacing(2),
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const VendorsModal = ({ allVendors, showModal, setShowModal, setSupplier }: any) => {
  const classes = useStyles({});

  const [vendors, setVendors] = useState(allVendors);
  const [search, setSearch] = useState('');

  const filterVendors = () => {
    if (search.length > 0) {
      const filteredVendors: any = [];
      allVendors.forEach((item: any) => {
        const itemData = item.vendorName ? item.vendorName.toUpperCase() : ''.toUpperCase();
        const textData = search.toUpperCase();
        if (itemData.indexOf(textData) > -1) {
          filteredVendors.push(item);
        }
      });
      return filteredVendors;
    }

    return allVendors;
  };

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      hideBackdrop={true}
      title="Select Supplier"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <SearchBar
          value={search}
          placeholder="Search Suppliers"
          onChange={(text) => {
            setSearch(text);
          }}
          onSubmit={() => {
            const filteredVendors = filterVendors();
            setVendors(filteredVendors);
          }}
          onClear={() => {
            setSearch('');
            setVendors(allVendors);
          }}
        />
        <Box className={classes.spacer} />
        {Object.entries(vendors ?? []).map((item: any, key: any) => {
          return (
            <ButtonDropdownItem
              key={key}
              onClick={() => {
                setSupplier(item[1].vendorNum);
                setShowModal(false);
              }}
            >
              <Typography>{item[1].vendorName}</Typography>
            </ButtonDropdownItem>
          );
        })}
      </Box>
    </Modal>
  );
};
