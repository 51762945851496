import { useState, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Paper, Typography, Container, Divider, Box, SearchBar, Alert } from '@c2fo/react-components';

import { useQuery } from '@apollo/client';

import LoadingScreen from '../../components/Atoms/LoadingScreen/LoadingScreen';
import { Navigation } from '../../components/Molecules/Navigation/Navigation';

import { GET_USER_ACCOUNT_DETAILS } from '../../schemas/User.schema';
import { SELLERS } from '../../schemas/Sellers.schema';

import { AlertData, AlertType } from '../../types/Common';

import { defaultRowPerPage, PaginationDetailsProps, maxPerPageAllowedValue } from '../../constants/Constants';
import { Routes } from '../../constants/RouterLink';

import useStyles from './styles';
import { SellersTable } from '../../components/Molecules/SellersTable/SellersTable';
import { dateFormatter } from '../../util/util';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const SellersPage = () => {
  const history = useHistory();

  const urlQuery = new URLSearchParams(window.location.search);
  const [search, setSearch] = useState(urlQuery.get('query') ?? '');
  const [alertData, setAlertData] = useState<AlertData>();
  const [sortCol, setSortCol] = useState('identifier');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [{ pageNumber, pagination }, setPaginationDetails] = useState<PaginationDetailsProps>({
    pageNumber: 0,

    pagination: {
      after: '',
      first: defaultRowPerPage,
    },
  });

  const classes = useStyles({});

  const { loading: userLoading, data: userData, error: userError } = useQuery(GET_USER_ACCOUNT_DETAILS, {
    variables: {
      fetchPolicy: 'cache-and-network',
    },
  });

  const commonSellersVariables = () => {
    const filters = {
      paging: { first: maxPerPageAllowedValue, after: '' },
      fetchPolicy: 'cache-and-network',
      sellerName: search.length > 0 ? `%${search}%` : '%',
      sortOrder,
      sortCol,
    };

    return filters;
  };

  const getSellerVariables = () => {
    const filters = {
      ...commonSellersVariables(),
      paging: pagination,
    };
    return filters;
  };

  const { loading: sellersLoading, data: sellersData, error: sellersError } = useQuery(SELLERS, {
    variables: userData && getSellerVariables(),
    skip: !userData,
  });

  useEffect(() => {
    if (!userError) return;
    setAlertData({
      message: 'Something went wrong',
      showAlert: true,
      type: AlertType.ERROR,
    });
  }, [userError]);

  const dataForTable = useMemo(() => {
    return sellersData?.sellers?.edges?.map((item: any) => item.node);
  }, [sellersData]);

  const showLoading = () => {
    if (userLoading) return <LoadingScreen />;
  };

  if (!userLoading && !userData?.getSellerAccountDetails?.efiAuditor) {
    return <Redirect to={{ pathname: Routes.Tnc }} />;
  }

  return (
    <div className={classes.wrapper}>
      <Navigation activeItem={'Sellers'} user={{ userData, userLoading }} />
      {showLoading() || (
        <>
          <Paper elevation={0}>
            <Container maxWidth="xl" className={classes.mainContainer}>
              <Box className={classes.card}>
                <div className={classes.cardHeader}>
                  <Box className={classes.cardHeaderSubtitleRow}>
                    <Typography className={classes.cardHeaderTitle} variant="h6">
                      SELLERS
                    </Typography>
                    <Box style={{ flexDirection: 'row', display: 'flex' }}>
                      <SearchBar
                        value={search}
                        placeholder="Search Sellers"
                        onChange={(text) => {
                          setSearch(text);
                        }}
                        onSubmit={(text) => {
                          setSearch(text);
                        }}
                        onClear={() => {
                          setSearch('');
                        }}
                      />
                    </Box>
                  </Box>
                </div>
                <Divider style={{ marginBottom: 15 }} />
                <SellersTable
                  data={dataForTable}
                  dataCount={10}
                  dataLoading={sellersLoading}
                  dataError={!!sellersError}
                  pageInfo={sellersData?.bankAggregations?.pageInfo}
                  pageNumber={search.length > 0 ? 0 : pageNumber}
                  setPaginationDetails={setPaginationDetails}
                  setSortCol={setSortCol}
                  setSortOrder={setSortOrder}
                />
              </Box>
            </Container>

            {alertData && (
              <Alert
                variant={alertData.type}
                open={alertData.showAlert}
                onClose={() => setAlertData(undefined)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                {alertData?.message}
              </Alert>
            )}
            <div className={classes.spacer} />
          </Paper>
        </>
      )}
    </div>
  );
};
