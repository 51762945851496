import { useServices } from '../services';

interface CurrencyProps {
  amount?: string | number;
  currency: string;
}

/**
 * @function CurrencyFormatter
 * @description Use this function to formate a currency, it extends `currencyService`.
 */
const CurrencyFormatter = ({ amount, currency }: CurrencyProps): string => {
  const { currencyService } = useServices();

  if (!amount && amount !== 0) return '--';
  return currencyService.format('en', currency, parseFloat(String(amount)));
};

export default CurrencyFormatter;
