import { makeStyles, Theme, Liquidity } from '@c2fo/react-components';
import { tableCol } from '../../../constants/ColorPalette';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    position: 'relative',
    boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.2)',
  },
  cardHeader: {
    padding: theme.spacing(2.5),
    width: '100%',
    borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
  },
  cardHeaderTitleRow: {
    height: 25,
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderTitle: {
    marginBottom: 0,

    color: Liquidity.colors.text.standard,
    fontWeight: 'bold',
    fontSize: 17,
  },
  cardHeaderSubtitleRow: {
    height: 35,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderSubtitle: {
    fontSize: 13,
    color: Liquidity.colors.text.standardLight,
  },

  spacer: {
    height: theme.spacing(5),
  },

  mainContainer: {
    width: '98%',
  },
  textRight: {
    textAlign: 'right',
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  amountColTitle: {
    color: tableCol,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  paymenDateCol: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  payDateTitle: {
    color: tableCol,
    fontWeight: 'bold',
  },
  payDateInfo: {
    height: 18,
    color: tableCol,
  },
  marqueTextRow: {
    height: 25,
    marginTop: 30,
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  marqueText: {
    color: Liquidity.colors.primary.deepGreen,
  },
}));

export default useStyles;
