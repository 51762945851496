import { useState, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import {
  Paper,
  Typography,
  Container,
  AngleDownIcon,
  FilterIcon,
  DownloadIcon,
  Grid,
  Divider,
  Box,
  SearchBar,
  ButtonDropdown,
  ButtonDropdownItem,
  Alert,
  AncillaryButton,
} from '@c2fo/react-components';

import { useQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';

import LoadingScreen from '../../components/Atoms/LoadingScreen/LoadingScreen';
import { Navigation } from '../../components/Molecules/Navigation/Navigation';

import { GET_USER_ACCOUNT_DETAILS } from '../../schemas/User.schema';
import { GET_ALL_VENDORS } from '../../schemas/Invoice.schema';
import { REPAYMENTS, REPAYMENTS_SUMMARY } from '../../schemas/Repayment.schema';

import { AlertData, AlertType } from '../../types/Common';

import {
  defaultRowPerPage,
  PaginationDetailsProps,
  RepaymentCsvHeaders,
  maxPerPageAllowedValue,
} from '../../constants/Constants';
import { Routes } from '../../constants/RouterLink';

import useStyles from './styles';
import { RepaymentSummary } from '../../components/Molecules/RepaymentSummary/RepaymentSummary';
import { RepaymentTable } from '../../components/Molecules/RepaymentTable/RepaymentTable';
import { dateFormatter } from '../../util/util';
import CurrencyFormatter from '../../util/CurrencyFormatter';
import { VendorsModal } from '../../components/Molecules/VendorsModal/VendorsModal';
import { SellerName } from '../../components/Molecules/SellerName/SellerName';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint max-statements: ["error", 50] */
/* eslint complexity: ["error", 25] */

export const RepaymentPage = () => {
  const history = useHistory();

  const REPAYMENT_STATUS_MAPING = {
    All: 'PROCESSED,PARTIALLY SETTLED,PARTIAL PROCESSED',
    Financed: 'PROCESSED,PARTIAL PROCESSED',
    Repaid: 'SETTLED',
    'Partially Repaid': 'PARTIALLY SETTLED',
    'Rejected by Bank': 'BANK REJECTED',
  };

  const urlQuery = new URLSearchParams(window.location.search);
  const [search, setSearch] = useState(urlQuery.get('query') ?? '');
  const [alertData, setAlertData] = useState<AlertData>();
  const [time, setTime] = useState('All');
  const [funder, setFunder] = useState(urlQuery.get('funder') ?? 'All');
  const [supplier, setSupplier] = useState(urlQuery.get('supplier') ?? 'All');
  const [sortCol, setSortCol] = useState('loanRefNumber');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [showModal, setShowModal] = useState(false);
  const [{ pageNumber, pagination }, setPaginationDetails] = useState<PaginationDetailsProps>({
    pageNumber: 0,

    pagination: {
      after: '',
      first: defaultRowPerPage,
    },
  });

  const filterTimeOptions = ['All', '30 Days', '60 Days', '90 Days'];
  const funderOptions = ['All', 'ICICI Bank', 'HSBC'];

  const classes = useStyles({});

  const { loading: userLoading, data: userData, error: userError } = useQuery(GET_USER_ACCOUNT_DETAILS, {
    variables: {
      fetchPolicy: 'cache-and-network',
    },
  });

  const { loading: allVendorsLoading, error: allVendorsError, data: allVendorsData } = useQuery(GET_ALL_VENDORS, {
    variables: userData && {
      sellerId: localStorage.seller ?? userData?.getSellerAccountDetails?.identifier,
    },
    skip: !userData,
  });

  const commonRepaymentVariables = () => {
    const filters = {
      paging: { first: maxPerPageAllowedValue, after: '' },
      fetchPolicy: 'cache-and-network',
      invoiceDate: '2022-08-01',
      dateApproved: '2022-08-01',
      status: REPAYMENT_STATUS_MAPING.All.split(','),
      sellerId: localStorage.seller ?? userData?.getSellerAccountDetails?.identifier,
      vendorNum: supplier !== 'All' ? supplier : '%',
      bank: funder !== 'All' ? funder : '%',
      loanRefNumber: search.length > 0 ? `%${search}%` : '%',
      sortOrder,
      sortCol,
    };

    if (time !== 'All') {
      const date = new Date();
      date.setDate(date.getDate() - parseInt(time.replace(' Days', ''), 10));
      filters.invoiceDate = dateFormatter(date.toDateString(), 'yyyy-MM-dd');
      filters.dateApproved = dateFormatter(date.toDateString(), 'yyyy-MM-dd');
    }
    return filters;
  };

  const getRepaymentVariables = () => {
    const filters = {
      ...commonRepaymentVariables(),
      paging: pagination,
    };
    return filters;
  };

  const { loading: summaryLoading, data: summaryData, error: summaryError } = useQuery(REPAYMENTS_SUMMARY, {
    variables: userData && { sellerId: localStorage.seller ?? userData?.getSellerAccountDetails?.identifier },
    skip: !userData,
  });

  const { loading: repaymentsLoading, data: repaymentsData, error: repaymentsError } = useQuery(REPAYMENTS, {
    variables: userData && getRepaymentVariables(),
    skip: !userData,
  });

  const { loading: repaymentsCsvLoading, error: repaymentsCsvError, data: repaymentsCsvData } = useQuery(REPAYMENTS, {
    variables: userData && commonRepaymentVariables(),
    skip: !userData,
  });

  useEffect(() => {
    if (!userError || allVendorsError) return;
    setAlertData({
      message: 'Something went wrong',
      showAlert: true,
      type: AlertType.ERROR,
    });
  }, [userError, allVendorsError]);

  const allVendors = useMemo(() => {
    let data = [{ __typename: 'Vendor', vendorName: 'All', vendorNum: 'All' }];
    data = data.concat(allVendorsData?.getAllVendors?.data ?? []);
    return data;
  }, [allVendorsData]);

  const supplierName = () => {
    return allVendors.filter((item) => item?.vendorNum === supplier)[0]?.vendorName;
  };

  const dataForTable = useMemo(() => {
    return repaymentsData?.bankAggregations?.edges?.map((item: any) => item.node);
  }, [repaymentsData]);

  const dataForCsv = useMemo(() => {
    return repaymentsCsvData?.bankAggregations?.edges?.map((item: any) => item.node);
  }, [repaymentsCsvData]);

  const showLoading = () => {
    if (userLoading || allVendorsLoading) return <LoadingScreen />;
  };

  const csvReportData = () => {
    const data = (dataForCsv || []).map((row: any) => {
      return [
        `${row.loanRefNumber}`,
        dateFormatter(row.dateApproved, 'yyyy-MM-dd'),
        CurrencyFormatter({ amount: row.approvedInvoiceAmount, currency: 'INR' }),
        dateFormatter(row.bankDueDate, 'yyyy-MM-dd'),
        CurrencyFormatter({ amount: row.loanAmountOutstanding, currency: 'INR' }),
      ];
    });
    return data;
  };

  const supplierFilter = () => {
    return (
      <Box className={classes.filterBox}>
        <Typography>Supplier:</Typography>
        <AncillaryButton
          className={classes.dropdownFilterBtn}
          endIcon={<AngleDownIcon />}
          onClick={() => {
            setShowModal(true);
          }}
          style={{ textTransform: 'capitalize' }}
        >
          {supplierName() ?? 'All'}
        </AncillaryButton>
      </Box>
    );
  };

  const funderFilter = () => {
    return (
      <Box className={classes.filterBox}>
        <Typography>Funder:</Typography>
        <ButtonDropdown
          className={classes.dropdownFilterBtn}
          label={funder}
          placement={'bottom'}
          size={'small'}
          endIcon={<AngleDownIcon />}
        >
          {funderOptions.map((funderOption) => (
            <ButtonDropdownItem key={funderOption} onClick={() => setFunder(funderOption)}>
              <Typography>{funderOption}</Typography>
            </ButtonDropdownItem>
          ))}
        </ButtonDropdown>
      </Box>
    );
  };

  const timeFilter = () => {
    return (
      <Box className={classes.filterBox}>
        <Typography>Time:</Typography>
        <ButtonDropdown
          className={classes.dropdownFilterBtn}
          label={time}
          placement={'bottom'}
          size={'small'}
          endIcon={<AngleDownIcon />}
        >
          {filterTimeOptions.map((duration) => (
            <ButtonDropdownItem key={duration} onClick={() => setTime(duration)}>
              <Typography>{duration}</Typography>
            </ButtonDropdownItem>
          ))}
        </ButtonDropdown>
      </Box>
    );
  };

  if (!userLoading && !userData?.getSellerAccountDetails?.efiAuditor) {
    if (!userData?.getSellerAccountDetails?.tncAccepted) {
      return <Redirect to={{ pathname: Routes.Tnc }} />;
    }
  }

  if (!userLoading && userData?.getSellerAccountDetails?.efiAuditor) {
    if (!localStorage.seller) {
      return <Redirect to={{ pathname: Routes.Sellers }} />;
    }
  }

  return (
    <div className={classes.wrapper}>
      <Navigation activeItem={'Repayment'} user={{ userData, userLoading }} />
      {showLoading() || (
        <>
          <Paper elevation={0}>
            <Container maxWidth="xl" className={classes.mainContainer}>
              <SellerName isEfiAuditor={userData?.getSellerAccountDetails?.efiAuditor} />
              <RepaymentSummary data={{ summaryData, summaryLoading, summaryError }} dataLoading={false} />
            </Container>
            <div className={classes.spacer} />
            <Divider />
            <Container maxWidth="xl" className={classes.mainContainer}>
              <Box className={classes.card}>
                <div className={classes.repaymentsTableHeader}>
                  <Grid container spacing={0}>
                    <Grid item sm={2} xs={12}>
                      <Box className={classes.filterBarTitleBox}>
                        <Typography className={classes.cardHeaderTitle} variant="h6">
                          REPAYMENTS
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <Box className={classes.repaymentsFilter}>
                        <FilterIcon className={classes.filterBarIcon} style={{ color: 'rgb(156, 154, 154)' }} />
                        {supplierFilter()}
                        {funderFilter()}
                        {timeFilter()}
                        <Box className={classes.searchBar}>
                          <SearchBar
                            value={search}
                            placeholder="Search Loan Ref"
                            onChange={(text) => {
                              setSearch(text);
                            }}
                            onSubmit={(text) => {
                              setSearch(text);
                            }}
                            onClear={() => {
                              setSearch('');
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid sm={1} xs={12}>
                      <Box className={classes.downloadIconBox}>
                        <CSVLink data={[RepaymentCsvHeaders].concat(csvReportData() || [])}>
                          <DownloadIcon className={classes.downloadIcon} />
                        </CSVLink>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
                <Divider style={{ marginBottom: 15 }} />
                <RepaymentTable
                  data={dataForTable}
                  dataCount={repaymentsData?.bankAggregations?.totalCount}
                  dataLoading={repaymentsLoading}
                  dataError={!!repaymentsError}
                  pageInfo={repaymentsData?.bankAggregations?.pageInfo}
                  pageNumber={search.length > 0 ? 0 : pageNumber}
                  setPaginationDetails={setPaginationDetails}
                  setSortCol={setSortCol}
                  setSortOrder={setSortOrder}
                />
              </Box>
            </Container>
            {!allVendorsLoading && (
              <>
                <VendorsModal
                  allVendors={allVendors}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setSupplier={setSupplier}
                />
              </>
            )}
            {alertData && (
              <Alert
                variant={alertData.type}
                open={alertData.showAlert}
                onClose={() => setAlertData(undefined)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                {alertData?.message}
              </Alert>
            )}
            <div className={classes.spacer} />
          </Paper>
        </>
      )}
    </div>
  );
};
