import { Box, makeStyles, Typography, Grid, Liquidity, Theme } from '@c2fo/react-components';
import CurrencyFormatter from '../../../util/CurrencyFormatter';

const useStyles = makeStyles((theme: Theme) => ({
  summaryBox: {
    padding: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  summarBoxMid: {
    borderLeft: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    borderRight: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    '@media (max-width: 599px)': {
      borderLeft: '0px solid',
      borderRight: '0px solid',
      borderTop: `1px solid ${Liquidity.colors.misc.formDisabled}`,
      borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    },
  },
  summaryCardBody: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  cardHeader: {
    padding: theme.spacing(2.5),
    width: '100%',
    borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
  },
  cardHeaderTitleRow: {
    height: 25,
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderTitle: {
    marginBottom: 0,

    color: Liquidity.colors.text.standard,
    fontWeight: 'bold',
    fontSize: 17,
  },
  card: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2.5),
    position: 'relative',
    boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.2)',
  },
  summaryBoxTitle: {
    color: Liquidity.colors.text.standard,
  },

  summaryBoxSubtitle: {
    color: Liquidity.colors.text.standardLight,
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const AuthorisationSummary = ({ dataLoading, data }: any) => {
  const classes = useStyles({});

  const totalDiscount = () => {
    const discount = data?.getAggregationSummaryForSeller?.totalDiscount;
    return discount !== undefined && discount !== null ? CurrencyFormatter({ amount: discount, currency: 'INR' }) : '0';
  };

  const totalPayableAmount = () => {
    const totalAmnt = data?.getAggregationSummaryForSeller?.totalPayableAmount;
    return totalAmnt !== undefined && totalAmnt !== null
      ? CurrencyFormatter({ amount: totalAmnt, currency: 'INR' })
      : '0';
  };

  return (
    <Box className={classes.card}>
      <div className={classes.cardHeader}>
        <Box className={classes.cardHeaderTitleRow}>
          <Typography className={classes.cardHeaderTitle} variant="h6">
            SUMMARY
          </Typography>
        </Box>
      </div>
      <div className={classes.summaryCardBody}>
        <Grid container spacing={0}>
          <Grid item sm={4} xs={12}>
            <Box p={3} className={classes.summaryBox} bgcolor={Liquidity.colors.text.inverse}>
              <Typography className={classes.summaryBoxTitle} variant="body1">
                Total Discount
              </Typography>
              <Typography className={classes.summaryBoxSubtitle} variant="h5">
                {dataLoading ? 'Loading...' : totalDiscount()}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box
              p={3}
              className={[classes.summaryBox, classes.summarBoxMid].join(' ')}
              bgcolor={Liquidity.colors.text.inverse}
            >
              <Typography className={classes.summaryBoxTitle} variant="body1">
                Total Amount Payable
              </Typography>
              <Typography className={classes.summaryBoxSubtitle} variant="h5">
                {dataLoading ? 'Loading...' : totalPayableAmount()}
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box p={3} className={classes.summaryBox} bgcolor={Liquidity.colors.text.inverse}>
              <Typography className={classes.summaryBoxTitle} variant="body1">
                Suppliers
              </Typography>
              <Typography className={classes.summaryBoxSubtitle} variant="h5">
                {dataLoading ? 'Loading...' : data?.getAggregationSummaryForSeller?.suppliers ?? '0'}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
