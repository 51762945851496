import { useState, useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import {
  Paper,
  Typography,
  Container,
  InfoIcon,
  PrimaryButton,
  Divider,
  SearchBar,
  Box,
  IconButton,
  CircleIcon,
  CheckCircleIcon,
  Link,
  Alert,
  Tooltip,
} from '@c2fo/react-components';

import { useQuery, useMutation, useLazyQuery } from '@apollo/client';

import { CellProps, OnPageChangeParams } from '../../../types/customTable.schema';
import { SortingDirection, AlertData, AlertType, AuthorizationSortingCol } from '../../../types/Common';
import {
  AUTHORIZE_INVOICES,
  AUTHORIZATION_LOCK,
  AUTHORIZATION_SUMMARY,
  AUTHORIZATION_INVOICES,
} from '../../../schemas/Authorization.schema';

import useStyles from './styles';
import './marqueeText.css';

import CustomTable from '../../Atoms/CustomTable/CustomTable';
import {
  SOMETHING_WENT_WRONG_MSG,
  authInvoicesRowsPerPage,
  PaginationDetailsProps,
} from '../../../constants/Constants';
import { Routes } from '../../../constants/RouterLink';
import CurrencyFormatter from '../../../util/CurrencyFormatter';
import { dateFormatter } from '../../../util/util';
import { AuthorisationSummary } from '../../Molecules/AuthorisationSummary/AuthorisationSummary';
import LoadingScreen from '../../Atoms/LoadingScreen/LoadingScreen';
import { SellerName } from '../../Molecules/SellerName/SellerName';

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint max-statements: ["error", 50] */
/* eslint complexity: ["error", 20] */

export const Authorization = ({ user }: any) => {
  const history = useHistory();

  const urlQuery = new URLSearchParams(window.location.search);

  const [search, setSearch] = useState(urlQuery.get('query') ?? '');
  const [alertData, setAlertData] = useState<AlertData>();
  const [invoicesToBeAuthorized, setInvoicesToBeAuthorized] = useState([]);
  const [sortCol, setSortCol] = useState('vendorName');
  const [sortOrder, setSortOrder] = useState('ASC');

  const [{ pageNumber, pagination }, setPaginationDetails] = useState<PaginationDetailsProps>({
    pageNumber: 0,

    pagination: {
      after: '',
      first: authInvoicesRowsPerPage,
    },
  });

  const classes = useStyles({});

  const authInvoicesVariables = () => {
    const filters = {
      fetchPolicy: 'cache-and-network',
      sellerId: localStorage.seller ?? user?.userData?.getSellerAccountDetails?.identifier,
      vendorName: search.length > 0 ? `%${search}%` : '%',
      funder: search.length > 0 ? `%${search}%` : '%',
      paging: pagination,
      sortCol,
      sortOrder,
    };

    return filters;
  };

  const { loading: summaryLoading, data: summaryData, error: summaryError } = useQuery(AUTHORIZATION_SUMMARY, {
    variables: {
      sellerId: localStorage.seller ?? user?.userData?.getSellerAccountDetails?.identifier.toString(),
      fetchPolicy: 'cache-and-network',
    },
  });

  const [getInvoices, { loading: authLoading, data: authData }] = useLazyQuery(AUTHORIZATION_INVOICES, {
    variables: authInvoicesVariables(),
  });

  const { loading: authInvoicesLoading, error: authInvoicesError, data: authInvoicesData } = useQuery(
    AUTHORIZATION_INVOICES,
    {
      variables: authInvoicesVariables(),
    },
  );

  const { loading: authLockLoading, data: authLockData, error: authLockError } = useQuery(AUTHORIZATION_LOCK, {
    variables: {
      date: dateFormatter(Date(), 'yyyy-MM-dd'),
      fetchPolicy: 'cache-and-network',
    },
  });

  const showInvoiceAuthCheckBox = (rowData: any) => {
    return rowData.isAuthorized || invoicesToBeAuthorized.indexOf(rowData.id as never) > -1 ? (
      <CheckCircleIcon />
    ) : (
      <CircleIcon />
    );
  };

  const [markAggregationsAuthorised, { data: authorizeInvoicesData }] = useMutation(AUTHORIZE_INVOICES);

  useEffect(() => {
    if (!authorizeInvoicesData) return;
    const statusData = authorizeInvoicesData.markAggregationsAuthorised;
    setAlertData({
      message: statusData?.error?.length > 0 ? statusData.error : 'Authorization performed successfully',
      showAlert: true,
      type: statusData.status === 'SUCCESS' ? AlertType.SUCCESS : AlertType.ERROR,
    });
    getInvoices();
  }, [authorizeInvoicesData]);

  useEffect(() => {
    if (!(summaryError || authLockError)) return;
    setAlertData({
      message: SOMETHING_WENT_WRONG_MSG,
      showAlert: true,
      type: AlertType.ERROR,
    });
  }, [summaryError, authLockError]);

  const vendorNameCell = ({ rowData }: CellProps) => {
    return <Link href={`${Routes.Invoices}?supplier=${rowData.vendorNum}`}>{rowData.vendorName}</Link>;
  };

  const funderCell = ({ rowData }: CellProps) => {
    return <Link href={`${Routes.Repayment}?funder=${rowData.funder}`}>{rowData.funder}</Link>;
  };

  const dataForTable = useMemo(() => {
    return authInvoicesData?.aggregations?.edges?.map((item: any) => item.node);
  }, [authInvoicesData]);

  const isUserEfiAuditor = useMemo(() => {
    return user?.userData?.getSellerAccountDetails?.efiAuditor;
  }, [user]);

  const allInvoiceIds = useMemo(() => {
    return (dataForTable ?? [])?.map((item: any) => item.id);
  }, [authInvoicesData]);

  const amountFormattedCell = ({ rowData }: CellProps, columnKey: string) => {
    return (
      <Typography className={classes.textLeft}>
        {CurrencyFormatter({ amount: rowData[columnKey], currency: 'INR' })}
      </Typography>
    );
  };
  const dateFormattedCell = ({ rowData }: CellProps, columnKey: string, centerAligned: boolean) => {
    return (
      <Typography className={[centerAligned ? classes.textCenter : ''].join(' ')}>
        {dateFormatter(Date.parse(rowData[columnKey]), 'MMM. dd, yyyy')}
      </Typography>
    );
  };

  const moveToNextPage = () => {
    setPaginationDetails((prev) => ({
      pageNumber: prev.pageNumber + 1,
      pagination: {
        after: authInvoicesData?.aggregations?.pageInfo.endCursor,
        first: authInvoicesRowsPerPage,
      },
    }));
  };

  const moveToPreviousPage = () => {
    setPaginationDetails((prev) => ({
      pageNumber: prev.pageNumber - 1,
      pagination: {
        before: authInvoicesData?.aggregations?.pageInfo.startCursor,
        last: authInvoicesRowsPerPage,
      },
    }));
  };

  const handlePageChange = ({ currentPageNumber }: OnPageChangeParams) => {
    if (currentPageNumber > pageNumber) {
      moveToNextPage();
    } else if (currentPageNumber < pageNumber) {
      moveToPreviousPage();
    }
  };

  const amountColCell = ({ rowData }: CellProps, columnKey: string) => {
    return <Typography className={classes.textLeft}>{rowData[columnKey]}</Typography>;
  };

  const invoicesSelectForAuthorizartionCell = ({ rowData }: CellProps) => {
    return !isUserEfiAuditor ? (
      <IconButton
        onClick={() => {
          if (!rowData.isAuthorized) {
            setInvoicesToBeAuthorized((pre: any) => {
              const invoiceSelected = pre.indexOf(rowData.id as never) > -1;
              const invoicesSelected = Object.assign([], pre);
              if (invoiceSelected) {
                invoicesSelected.splice(invoicesSelected.indexOf(rowData.id as never), 1);
              } else {
                invoicesSelected.push(rowData.id as never);
              }
              return invoicesSelected;
            });
          }
        }}
        color="default"
      >
        {showInvoiceAuthCheckBox(rowData)}
      </IconButton>
    ) : (
      <Box></Box>
    );
  };

  const isAuthLocked = () => {
    if (authLockLoading) return false;
    return authLockData?.authorisationLocks?.edges[0]?.node?.locked ?? true;
  };

  const isAuthorizeBtnDisabled = () => {
    if (authLockLoading) return false;
    if (authLockData?.authorisationLocks?.edges[0]?.node?.locked === true) return true;
    if (authLockData?.authorisationLocks?.edges[0]?.node?.locked === false) {
      return invoicesToBeAuthorized.length === 0;
    }
  };

  const handleSorting = (direction: SortingDirection, sortingKey: AuthorizationSortingCol) => {
    setSortCol(sortingKey);
    setSortOrder(direction.toLocaleUpperCase());
  };

  if (user?.userData?.getSellerAccountDetails?.efiAuditor) {
    if (!localStorage.seller) {
      return <Redirect to={{ pathname: Routes.Sellers }} />;
    }
  }

  return !authLoading ? (
    <Paper elevation={0}>
      <Container maxWidth="xl" data-testid="auth-summary" className={classes.mainContainer}>
        <SellerName isEfiAuditor={isUserEfiAuditor} />
        <AuthorisationSummary dataLoading={summaryLoading} data={summaryData} />
      </Container>
      <div className={classes.spacer} />
      <Divider />

      <Container maxWidth="xl" className={classes.mainContainer}>
        {!authLockLoading && dataForTable?.length > 0 && !isUserEfiAuditor && (
          <>
            <Box className={classes.marqueTextRow}>
              <Typography className={[classes.marqueText, 'marquee-animated'].join(' ')} variant="body1">
                {!isAuthLocked() ? (
                  <>
                    Please authorise these invoices before 6PM, so that your suppliers are paid on{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {dateFormatter(dataForTable[0]?.paymentDate, 'MMM. dd, yyyy')}
                    </span>
                  </>
                ) : (
                  'Authorisation cannot be performed between 6PM to 12.30PM'
                )}
              </Typography>
            </Box>
          </>
        )}
      </Container>

      <Container maxWidth="xl" className={classes.mainContainer}>
        <Box className={classes.card}>
          <div className={classes.cardHeader}>
            <Typography className={classes.cardHeaderTitle} variant="h6">
              AUTHORISE INVOICES
            </Typography>
            <Box className={classes.cardHeaderSubtitleRow}>
              <Typography className={classes.cardHeaderSubtitle} variant="h6">
                Select Invoices to Authorise
              </Typography>
              <Box style={{ flexDirection: 'row', display: 'flex' }}>
                {!isUserEfiAuditor && (
                  <>
                    <PrimaryButton
                      disabled={isAuthorizeBtnDisabled()}
                      onClick={() => {
                        markAggregationsAuthorised({
                          variables: {
                            ids: invoicesToBeAuthorized.join(','),
                          },
                        });
                      }}
                      size="small"
                    >
                      {authLockLoading ? 'LOADING...' : 'AUTHORISE'}
                    </PrimaryButton>
                    <div style={{ width: 20 }} />
                  </>
                )}
                <SearchBar
                  value={search}
                  placeholder="Search Suppliers"
                  onChange={(text) => {
                    setSearch(text);
                  }}
                  onSubmit={(text) => {
                    setSearch(text);
                  }}
                  onClear={() => {
                    setSearch('');
                  }}
                />
              </Box>
            </Box>
          </div>
          <Divider style={{ marginBottom: 15 }} />
          <CustomTable
            data={dataForTable}
            noDataText={'There are no invoices available for authorisation'}
            key={'companyId'}
            currentSelectedPageNumber={search.length > 0 ? 0 : pageNumber}
            rowsPerPage={authInvoicesRowsPerPage}
            config={[
              {
                columnLabel: !isUserEfiAuditor ? (
                  <IconButton
                    onClick={() => {
                      if (allInvoiceIds?.length === invoicesToBeAuthorized.length) {
                        setInvoicesToBeAuthorized([]);
                      } else {
                        setInvoicesToBeAuthorized(allInvoiceIds);
                      }
                    }}
                    color="default"
                  >
                    {allInvoiceIds?.length === invoicesToBeAuthorized.length ? <CheckCircleIcon /> : <CircleIcon />}
                  </IconButton>
                ) : (
                  <Box></Box>
                ),
                cell: (row) => invoicesSelectForAuthorizartionCell(row),
              },
              {
                columnLabel: 'Supplier Name',
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'vendorName'),
                cell: (row) => vendorNameCell(row),
              },
              {
                columnLabel: 'Invoices',
                cell: (row) => amountColCell(row, 'invoiceCount'),
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'invoiceCount'),
              },
              {
                columnLabel: 'Invoice Amount',
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'amount'),
                cell: (row) => amountFormattedCell(row, 'amount'),
              },
              {
                columnLabel: 'Discount',
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'discount'),
                cell: (row) => amountFormattedCell(row, 'discount'),
              },
              {
                columnLabel: 'Net Amount Payable',
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'amountPayable'),
                cell: (row) => amountFormattedCell(row, 'amountPayable'),
              },
              {
                columnLabel: (
                  <Box className={classes.paymenDateCol}>
                    <Typography className={classes.payDateTitle} variant="body2">
                      Payment Date
                    </Typography>
                    <Tooltip title="s.t authorisation before 6PM">
                      <IconButton>
                        <InfoIcon className={classes.payDateInfo} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'paymentDate'),
                cell: (row) => dateFormattedCell(row, 'paymentDate', false),
              },
              {
                columnLabel: 'Funder',
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'funder'),
                cell: (row) => funderCell(row),
              },
              {
                columnLabel: 'APR%',
                onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'discountPercent'),
                selectorKey: 'discountPercent',
              },
            ]}
            keyExtractor={'id'}
            errorMsg={!!authInvoicesError}
            onPageChange={handlePageChange}
            maxData={authInvoicesData?.aggregations?.totalCount ?? 0}
            isLoading={authInvoicesLoading}
          />
        </Box>
      </Container>
      {alertData && (
        <Alert
          variant={alertData.type}
          open={alertData.showAlert}
          onClose={() => setAlertData(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {alertData?.message}
        </Alert>
      )}
      <div className={classes.spacer} />
    </Paper>
  ) : (
    <LoadingScreen />
  );
};
export default Authorization;
