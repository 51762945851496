import { env } from '@c2fo/common-config';

export const config = {
  ADMIN_APP: env.getString('LEGACY_C2FO_ADMIN_UI_URL') || 'http://localhost:8088',
  IS_DEVELOPMENT: env.getString('NODE_ENV') === 'development' || false,

  BASE_API_URL: env.getString('ECOM_FINANCING_BASE_API_URL') || 'http://localhost:8080',
  REACT_APP_GATEWAY_URL: env.getString('REACT_APP_GATEWAY_URL') || 'https://uat-gateway.c2fo.com',
  C2FO_APP_URL: env.getString('C2FO_APP_URL') || 'https://uat-app.c2fo.com',
  C2FO_APP_LOGOUT_URL: env.getString('C2FO_APP_LOGOUT_URL') || 'https://uat-gateway.c2fo.com/api/c2fo/logout',
  ECOM_FINANCING_LIST_PAGING_LIMIT: env.getInteger('ECOM_FINANCING_LIST_PAGING_LIMIT') || 5000,
};
