import {
  Logo,
  makeStyles,
  Theme,
  AppBar,
  Box,
  Toolbar,
  AncillaryButton,
  Liquidity,
  MoneyBillIcon,
  ButtonDropdown,
  ButtonDropdownItem,
  CaretDownFilledIcon,
  IconButton,
  BellOutlinedIcon,
  Typography,
  Link,
} from '@c2fo/react-components';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../constants/RouterLink';
import { activeNavItem } from '../../../constants/ColorPalette';
import { useServices } from '../../../services';
import { config } from '../../../../config/config';
import { InactivityMonitor } from '../InactivityMonitor/InactivityMonitor';

const useStyles = makeStyles<Theme>((theme) => ({
  bar: {
    justifyContent: 'center',
    height: 80,
    borderBottom: `1px rgb(224, 224, 224) solid`,
    boxShadow: 'none',
  },
  logo: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  navBtn: {
    paddingLeft: 15,
    paddingRight: 15,
    height: 80,
    fontWeight: 'bold',
    backgroundColor: Liquidity.colors.text.inverse,
    textTransform: 'capitalize',
    color: Liquidity.colors.primary.deepGreen,
    borderRadius: 0,
    '&:hover': {
      height: 80,
      borderBottom: `2px solid ${Liquidity.colors.primary.deepGreen}`,
      backgroundColor: activeNavItem,
    },
  },

  activeBtn: {
    backgroundColor: activeNavItem,
    borderBottom: `2px solid ${Liquidity.colors.primary.deepGreen}`,
  },
}));
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const Navigation = ({ activeItem, user, canShowNavItems = true }: any) => {
  const history = useHistory();
  const { authService, tokenService } = useServices();
  const classes = useStyles();

  const userAuthenticated = authService?.isAuthenticated();
  const userDropdownOptions = ['Sign Out'];

  const navbarActiveItem = (navItemName: any) => {
    return activeItem === navItemName ? classes.activeBtn : '';
  };

  const isEfiAuditor = user?.userData?.getSellerAccountDetails?.efiAuditor;

  const userDetails = () => {
    return user?.userData?.getSellerAccountDetails !== undefined
      ? `${user?.userData?.getSellerAccountDetails?.name}`
      : 'N/A';
  };

  const canShowOtherNavItems = () => {
    return !isEfiAuditor || (isEfiAuditor && localStorage.seller?.length > 0);
  };
  const logout = async () => {
    await fetch(config.C2FO_APP_LOGOUT_URL, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ token: tokenService.getToken() }),
    }).then(() => {
      tokenService.clearToken('logout');
      window.location.replace(`${Routes.Login}`);
    });
  };

  return (
    <Box>
      <AppBar data-testid="navigation" position="static" color="transparent" component="nav" className={classes.bar}>
        <Toolbar variant="regular" style={{ width: '100%' }}>
          <Link href="/">
            <Logo color="primary" height="35px" className={classes.logo} />
          </Link>
          {userAuthenticated && (
            <>
              (
              {canShowNavItems && (
                <Box>
                  {isEfiAuditor && (
                    <>
                      <AncillaryButton
                        onClick={() => {
                          history.push(Routes.Sellers);
                        }}
                        className={[classes.navBtn, navbarActiveItem('Sellers')].join(' ')}
                      >
                        Sellers
                      </AncillaryButton>
                    </>
                  )}
                  {canShowOtherNavItems() && (
                    <>
                      <AncillaryButton
                        onClick={() => {
                          history.push(Routes.Authorisation);
                        }}
                        className={[classes.navBtn, navbarActiveItem('Authorisation')].join(' ')}
                      >
                        Authorisation
                      </AncillaryButton>
                      <AncillaryButton
                        onClick={() => {
                          history.push(Routes.Invoices);
                        }}
                        className={[classes.navBtn, navbarActiveItem('Invoices')].join(' ')}
                      >
                        Invoices
                      </AncillaryButton>
                      <AncillaryButton
                        onClick={() => {
                          history.push(Routes.Repayment);
                        }}
                        className={[classes.navBtn, navbarActiveItem('Repayment')].join(' ')}
                      >
                        Repayment
                      </AncillaryButton>
                    </>
                  )}
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Box>
                {canShowNavItems && (
                  <>
                    <AncillaryButton className={classes.navBtn} startIcon={<MoneyBillIcon />}>
                      INR
                    </AncillaryButton>

                    <IconButton className={[classes.navBtn].join(' ')}>
                      <BellOutlinedIcon aria-label="close" />
                    </IconButton>
                  </>
                )}
                <ButtonDropdown
                  className={[classes.navBtn].join(' ')}
                  label={user?.userLoading ? 'Loading...' : userDetails()}
                  placement={'bottom'}
                  endIcon={<CaretDownFilledIcon />}
                >
                  {userDropdownOptions.map((item) => {
                    return (
                      <ButtonDropdownItem
                        key={item}
                        onClick={() => {
                          if (item === 'Sign Out') logout();
                        }}
                      >
                        <Typography>{item}</Typography>
                      </ButtonDropdownItem>
                    );
                  })}
                </ButtonDropdown>
              </Box>
              )
            </>
          )}
        </Toolbar>
      </AppBar>
      {userAuthenticated ? <InactivityMonitor /> : null}
    </Box>
  );
};
