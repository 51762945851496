import { Helmet } from 'react-helmet';
import { C2foComponentsRootProvider, ErrorBoundary } from '@c2fo/react-components';
import { ServicesProvider } from './services';
import { Router } from './Routes/Router';
import { GlobalErrorPage } from './pages/GlobalErrorPage/GlobalErrorPage';

import faviconSm from '../assets/favicon-150x150.png';
import faviconLg from '../assets/favicon-300x300.png';
import { ApiApolloProvider } from './services/Services.client';

const App = () => (
  <ErrorBoundary render={() => <GlobalErrorPage />}>
    <AppHead />
    <ApiApolloProvider>
      <ServicesProvider>
        <C2foComponentsRootProvider>
          <Router />
        </C2foComponentsRootProvider>
      </ServicesProvider>
    </ApiApolloProvider>
  </ErrorBoundary>
);

const AppHead = () => {
  return (
    <Helmet>
      <title>@c2fo/ecom-financing-india-ui</title>
      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <link href={faviconLg} data-favicon-id="lg" rel="icon" type="image/x-icon" sizes="192x192" />
      <link href={faviconSm} data-favicon-id="sm" rel="icon" type="image/x-icon" sizes="32x32" />
      <link href={faviconLg} data-favicon-id="lg" rel="apple-touch-icon" />
    </Helmet>
  );
};

export default App;
