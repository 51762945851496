import { gql } from '@apollo/client';

// filter: { sellerId: { eq: $sellerId } }
export const AUTHORIZATION_INVOICES = gql`
  query aggregations(
    $sellerId: String!
    $vendorName: String
    $funder: String
    $sortCol: AggregationSortFields!
    $sortOrder: SortDirection!
    $paging: CursorPaging
  ) {
    aggregations(
      paging: $paging
      sorting: { field: $sortCol, direction: $sortOrder }
      filter: {
        isAuthorized: { is: false }
        sellerId: { eq: $sellerId }
        and: [{ or: [{ funder: { iLike: $funder } }, { vendorName: { iLike: $vendorName } }] }]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          vendorName
          vendorNum
          discount
          invoiceCount
          paymentDate
          funder
          amount
          amountPayable
          isAuthorized
          discountPercent
        }
        cursor
      }
    }
  }
`;

export const AUTHORIZE_INVOICES = gql`
  mutation markAggregationsAuthorised($ids: String!) {
    markAggregationsAuthorised(input: { ids: $ids }) {
      status
      error
    }
  }
`;

export const AUTHORIZATION_SUMMARY = gql`
  query getAggregationSummaryForSeller($sellerId: String!) {
    getAggregationSummaryForSeller(input: { sellerId: $sellerId }) {
      totalDiscount
      totalPayableAmount
      suppliers
    }
  }
`;

export const AUTHORIZATION_LOCK = gql`
  query($date: DateTime!) {
    authorisationLocks(filter: { date: { eq: $date } }) {
      edges {
        node {
          locked
        }
      }
    }
  }
`;
