import { Box, makeStyles, Typography, Theme, Liquidity } from '@c2fo/react-components';

const useStyles = makeStyles((theme: Theme) => ({
  sellerNameRow: {
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    paddingTop: theme.spacing(3),
  },
  sellerNameText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Liquidity.colors.text.standard,
  },
}));

interface SellerNameType {
  isEfiAuditor: boolean;
}

export const SellerName = ({ isEfiAuditor }: SellerNameType) => {
  const classes = useStyles({});

  return (
    <Box>
      {isEfiAuditor && localStorage.getItem('sellerName') && (
        <Box className={classes.sellerNameRow}>
          <Typography className={classes.sellerNameText} variant="h6">
            Seller: {localStorage.getItem('sellerName')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
