// For customTable.ts
export const defaultRowPerPage = 20;
export const maxPerPageAllowedValue = 100;
export const defaultInitialPageNumber = 1;
export const noCellData = '--';
export const TYPING_DEBOUNCE = 300;
export const SOMETHING_WENT_WRONG_MSG = 'Something went wrong';

export const InvoicesCsvHeaders = [
  'Invoice No',
  'Supplier Name',
  'Invoice date',
  'Invoice Due Date',
  'Original Invoice Amount',
  'Payable Amount',
  'APR%',
  'Discount',
  'Net Amount Payable',
  'Status',
  'UTR',
  'Payment Date',
  'DPE',
];

export const invoiceTableHeaders = [
  {
    columnLabel: 'Invoice No',
    columnKey: 'invoiceNum',
  },
  {
    columnLabel: 'Invoice Date',
    columnKey: 'invoiceDate',
    onSort: 'enabled',
  },
  {
    columnLabel: 'Invoice Due Date',
    columnKey: 'dueDate',
    onSort: 'enabled',
  },
  {
    columnLabel: 'Original Invoice Amount',
    columnKey: 'invoiceAmount',
    onSort: 'enabled',
  },
  {
    columnLabel: 'Payable Amount',
    columnKey: 'paymentAmount',
    onSort: 'enabled',
  },
  {
    columnLabel: 'APR%',
    columnKey: 'discountPercentage',
    onSort: 'enabled',
  },
  {
    columnLabel: 'DPE',
    onSort: 'enabled',
    columnKey: 'dpe',
  },
  {
    columnLabel: 'Discount',
    columnKey: 'discount',
    onSort: 'enabled',
  },

  {
    onSort: 'disbaled',
    columnLabel: 'Net Amount Payable',
  },

  {
    columnLabel: 'Status',
    columnKey: 'status',
    onSort: 'enabled',
  },

  {
    columnLabel: '',
    onSort: 'disbaled',
  },
];

export const RepaymentCsvHeaders = ['Loan Reference', 'Loan Date', 'Loan Amount', 'Due Date', 'Loan Outstanding'];

export const authInvoicesRowsPerPage = 10;

export type Maybe<T> = T | null;

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type CursorPaging = {
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: Maybe<Scalars['Int']>;
  /** Paginate last */
  last?: Maybe<Scalars['Int']>;
};

export interface PaginationDetailsProps {
  pageNumber: number;
  pagination: CursorPaging;
}
