import { Box, Container, Typography, makeStyles, Theme, Liquidity, Grid, InfoIcon } from '@c2fo/react-components';
import CurrencyFormatter from '../../../util/CurrencyFormatter';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '98%',
  },
  card: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2.5),
    boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.2)',
    position: 'relative',
  },
  cardHeader: {
    height: theme.spacing(7),
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
  },
  cardHeaderTitleRow: {
    height: 25,
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderTitle: {
    marginBottom: 0,
    paddingLeft: theme.spacing(2.5),
    color: Liquidity.colors.text.standard,
    fontWeight: 'bold',
    fontSize: 17,
  },
  cardHeaderSubtitleRow: {
    height: 35,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderSubtitle: {
    fontSize: 13,
    color: Liquidity.colors.text.standardLight,
  },

  summaryBox: {
    padding: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  summarBoxMid: {
    borderLeft: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    borderRight: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    [theme.breakpoints.down('xs')]: {
      borderLeft: '0px solid',
      borderRight: '0px solid',
      borderTop: `1px solid ${Liquidity.colors.misc.formDisabled}`,
      borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    },
  },
  summaryCardBody: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  summaryBoxTitle: {
    color: Liquidity.colors.text.standard,
  },
  summaryBoxSubtitle: {
    color: Liquidity.colors.text.standardLight,
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const InvoicesSummary = ({ data, dataLoading }: any) => {
  const classes = useStyles({});

  const totalInvoices = () => {
    return data?.invoiceAggregate?.count.invoiceId ?? '0';
  };

  const totalPayableAmount = () => {
    const totalAmnt = data?.invoiceAggregate?.sum.paymentAmount;
    return totalAmnt !== undefined && totalAmnt !== null
      ? CurrencyFormatter({ amount: totalAmnt, currency: 'INR' })
      : '0';
  };

  const totalDiscount = () => {
    const discount = data?.invoiceAggregate?.sum.discount;
    return discount !== undefined && discount !== null ? CurrencyFormatter({ amount: discount, currency: 'INR' }) : '0';
  };

  const statBox = (title: string, amount: string, centerBox: boolean) => {
    return (
      <Box
        p={3}
        className={!centerBox ? classes.summaryBox : [classes.summaryBox, classes.summarBoxMid].join(' ')}
        bgcolor={Liquidity.colors.text.inverse}
      >
        <Typography className={classes.summaryBoxTitle} variant="body1">
          {title}
        </Typography>
        <Typography className={classes.summaryBoxSubtitle} variant="h5">
          {dataLoading ? 'Loading...' : amount}
        </Typography>
      </Box>
    );
  };
  return (
    <Box className={classes.card}>
      <div className={classes.cardHeader}>
        <Box className={classes.cardHeaderTitleRow}>
          <Typography className={classes.cardHeaderTitle} variant="h6">
            INVOICES
          </Typography>
          <InfoIcon style={{ height: 18, color: Liquidity.colors.primary.deepGreen }} />
        </Box>
      </div>
      <div className={classes.summaryCardBody}>
        <Grid container spacing={0}>
          <Grid item sm={4} xs={12}>
            {statBox('Total Invoices', totalInvoices(), false)}
          </Grid>
          <Grid item sm={4} xs={12}>
            {statBox('Total Amount Payable', totalPayableAmount(), true)}
          </Grid>
          <Grid item sm={4} xs={12}>
            {statBox('Total Discount', totalDiscount(), false)}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
