import React from 'react';
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  NormalizedCacheObject,
  ApolloLink,
  DefaultOptions,
} from '@apollo/client';
import { TokenService } from '@c2fo/react-services';
import { onError } from '@apollo/client/link/error';

import { config } from '../../config/config';
import { Routes } from '../constants/RouterLink';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const getHttpLink = (url: string) =>
  new HttpLink({
    uri: `${url}/graphql`,
  });

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = new TokenService('C2FOAuthToken').getToken();
    let tokenHeader = {};

    tokenHeader = {
      authorization: `Bearer ${token}` || '',
    };
    return {
      headers: {
        ...headers,
        ...tokenHeader,
      },
    };
  });
  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError?.message.includes('Received status code 401')) {
    new TokenService('C2FOAuthToken').clearToken();
    window.location.href = Routes.Login;
  }
});

const getClient = (url: string): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([errorLink, authMiddleware, getHttpLink(url)]),
    defaultOptions,
  });
};

// Add more such gql client for different end points
const defaultServiceClient: ApolloClient<NormalizedCacheObject> = getClient(config.BASE_API_URL);

const ApiApolloProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={defaultServiceClient}>{children}</ApolloProvider>;
};

export { defaultServiceClient, ApiApolloProvider };
