import { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Theme,
  Liquidity,
  Grid,
  IconButton,
  AngleDownIcon,
  AngleUpIcon,
} from '@c2fo/react-components';
import CurrencyFormatter from '../../../util/CurrencyFormatter';

import { CircularProgressWithLabel } from '../../Atoms/CircularProgressWithLabel/CircularProgressWithLabel';

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    width: '98%',
  },
  card: {
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2.5),
    boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 0.2)',
    position: 'relative',
  },
  cardHeader: {
    height: theme.spacing(7),
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
  },

  cardHeaderTitle: {
    marginBottom: 0,
    paddingLeft: theme.spacing(2.5),
    color: Liquidity.colors.text.standard,
    fontWeight: 'bold',
    fontSize: 17,
  },
  cardHeaderSubtitleRow: {
    height: 35,
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  cardHeaderSubtitle: {
    fontSize: 13,
    color: Liquidity.colors.text.standardLight,
  },

  summaryBox: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  summarBoxMid: {
    height: '100%',
    display: 'flex',
    flex: 1,
    borderRight: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    [theme.breakpoints.down('xs')]: {
      borderLeft: '0px solid',
      borderRight: '0px solid',
      borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    },
  },
  summaryCardBody: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  summaryBoxTitle: {
    color: Liquidity.colors.text.standard,
  },
  summaryBoxSubtitle: {
    color: Liquidity.colors.text.standardLight,
  },

  overallSummaryPercentageBox: {
    borderRight: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    [theme.breakpoints.down('xs')]: {
      borderLeft: '0px solid',
      borderRight: '0px solid',
      borderBottom: `1px solid ${Liquidity.colors.misc.formDisabled}`,
    },
  },

  arrowBox: {
    height: '100%',
  },
  funderDetailsSection: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),

    display: 'flex',
    borderTop: `1px solid ${Liquidity.colors.misc.formDisabled}`,
  },
  funderDetailsRow: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(1),
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const RepaymentSummary = ({ data }: any) => {
  const classes = useStyles({});

  const [showSummaryBankLevelDetails, setShowSummaryBankLevelDetails] = useState(false);

  const creditLimit = () => {
    return CurrencyFormatter({ amount: data?.summaryData?.getAllSummary?.totalCreditLimit, currency: 'INR' });
  };

  const utilization = () => {
    return CurrencyFormatter({
      amount: data?.summaryData?.getAllSummary?.totalLoanUtitlization,
      currency: 'INR',
    });
  };

  const bankLevelStat = (value: number) => {
    return CurrencyFormatter({
      amount: value,
      currency: 'INR',
    });
  };

  const summaryPercentage = () => {
    const totalCreditLimit = data?.summaryData?.getAllSummary?.totalCreditLimit ?? 0;
    const totalLoanUtitlization = data?.summaryData?.getAllSummary?.totalLoanUtitlization ?? 0;

    if (totalCreditLimit === 0 && totalLoanUtitlization === 0) {
      return 0;
    }
    return (totalLoanUtitlization / totalCreditLimit) * 100;
  };

  const bankLevelSummaryPercentage = (bankCreditLimit: number, bankUtilization: number) => {
    if (bankCreditLimit === 0 && bankUtilization === 0) {
      return 0;
    }
    return (bankUtilization / bankCreditLimit) * 100;
  };
  const limitAvailable = () => {
    return CurrencyFormatter({ amount: data?.summaryData?.getAllSummary?.totalCreditAvailable, currency: 'INR' });
  };

  const statBox = (title: string, amount: string, centerBox: boolean) => {
    return (
      <Box
        p={3}
        className={!centerBox ? classes.summaryBox : [classes.summaryBox, classes.summarBoxMid].join(' ')}
        bgcolor={Liquidity.colors.text.inverse}
      >
        <Typography className={classes.summaryBoxTitle} variant="body1">
          {title}
        </Typography>
        <Typography className={classes.summaryBoxSubtitle} variant="h5">
          {data?.summaryLoading ? 'Loading...' : `${data?.summaryError ? '0' : amount}`}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.card}>
      <div className={classes.cardHeader}>
        <Typography className={classes.cardHeaderTitle} variant="h6">
          REPAYMENTS
        </Typography>
      </div>
      <div className={classes.summaryCardBody}>
        <Grid container spacing={0}>
          <Grid item sm={2} xs={12}>
            <Box
              className={[classes.summaryBox, classes.overallSummaryPercentageBox].join(' ')}
              bgcolor={Liquidity.colors.text.inverse}
            >
              <CircularProgressWithLabel progress={Math.round(summaryPercentage())} />
            </Box>
          </Grid>
          <Grid container sm={10} xs={12}>
            <Grid item sm={4} xs={12}>
              {statBox('Credit Limit', creditLimit(), true)}
            </Grid>
            <Grid item sm={4} xs={12}>
              {statBox('Utilization', utilization(), true)}
            </Grid>
            <Grid item sm={3} xs={12}>
              {statBox('Limit Available', limitAvailable(), false)}
            </Grid>
            <Grid item sm={1} xs={12}>
              <Box
                p={3}
                className={[classes.summaryBox, classes.arrowBox].join(' ')}
                bgcolor={Liquidity.colors.text.inverse}
              >
                <IconButton
                  onClick={() => {
                    setShowSummaryBankLevelDetails(!showSummaryBankLevelDetails);
                  }}
                >
                  {showSummaryBankLevelDetails ? (
                    <AngleUpIcon className="fontSizeLarge" />
                  ) : (
                    <AngleDownIcon className="fontSizeLarge" />
                  )}
                </IconButton>
              </Box>
            </Grid>
            {showSummaryBankLevelDetails && (
              <>
                <Grid container sm={12} xs={12}>
                  <Box className={classes.funderDetailsSection}>
                    {(data?.summaryData?.getAllSummary?.data || []).map((item: any, key: any) => {
                      return (
                        <Box key={key} className={classes.funderDetailsRow}>
                          <Grid item sm={2} xs={12}>
                            <Box className={classes.summaryBox} bgcolor={Liquidity.colors.text.inverse}>
                              <CircularProgressWithLabel
                                progress={Math.round(bankLevelSummaryPercentage(item?.creditLimit, item?.utilization))}
                              />
                            </Box>
                          </Grid>
                          <Grid item sm={3} xs={12}>
                            {statBox('Funder', item.bank, false)}
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            {statBox('Credit Limit', bankLevelStat(item?.creditLimit), false)}
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            {statBox('Utilization', bankLevelStat(item?.utilization), false)}
                          </Grid>
                        </Box>
                      );
                    })}
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};
