import { Typography, Liquidity, makeStyles, Theme, Chip, AngleDownIcon } from '@c2fo/react-components';
import { dateFormatter } from '../../../util/util';
import CurrencyFormatter from '../../../util/CurrencyFormatter';

import { CellProps, OnPageChangeParams } from '../../../types/customTable.schema';

import CustomTable from '../../Atoms/CustomTable/CustomTable';

import { tableCol } from '../../../constants/ColorPalette';
import { RepaymentsSortingCol, SortingDirection } from '../../../types/Common';
import { defaultRowPerPage, PaginationDetailsProps } from '../../../constants/Constants';

const useStyles = makeStyles((theme: Theme) => ({
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  amountColTitle: {
    color: tableCol,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  chipCell: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    display: 'flex',
  },
}));

/* eslint-disable @typescript-eslint/no-explicit-any */
export const RepaymentTable = ({
  data,
  dataCount,
  dataLoading,
  dataError,
  pageInfo,
  pageNumber,
  setPaginationDetails,
  setSortCol,
  setSortOrder,
}: any) => {
  const classes = useStyles({});

  const dateFormattedCell = ({ rowData }: CellProps, columnKey: any) => {
    return dateFormatter(Date.parse(rowData[columnKey]), 'MMM. dd, yyyy');
  };

  const moveToNextPage = () => {
    setPaginationDetails((prev: PaginationDetailsProps) => ({
      pageNumber: prev.pageNumber + 1,
      pagination: {
        after: pageInfo.endCursor,
        first: defaultRowPerPage,
      },
    }));
  };

  const moveToPreviousPage = () => {
    setPaginationDetails((prev: PaginationDetailsProps) => ({
      pageNumber: prev.pageNumber - 1,
      pagination: {
        before: pageInfo.startCursor,
        last: defaultRowPerPage,
      },
    }));
  };

  const handlePageChange = ({ currentPageNumber }: OnPageChangeParams) => {
    if (currentPageNumber > pageNumber) {
      moveToNextPage();
    } else if (currentPageNumber < pageNumber) {
      moveToPreviousPage();
    }
  };

  const handleSorting = (direction: SortingDirection, sortingKey: RepaymentsSortingCol) => {
    setSortCol(sortingKey);
    setSortOrder(direction.toLocaleUpperCase());
  };

  const amountFormattedCell = ({ rowData }: CellProps, columnKey: any) => {
    return (
      <Typography className={classes.textLeft}>
        {CurrencyFormatter({ amount: rowData[columnKey], currency: 'INR' })}
      </Typography>
    );
  };

  return (
    <CustomTable
      data={data}
      currentSelectedPageNumber={pageNumber}
      onPageChange={handlePageChange}
      config={[
        {
          columnLabel: 'Loan Reference',
          selectorKey: 'loanRefNumber',
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'loanRefNumber'),
        },
        {
          columnLabel: 'Loan Date',
          cell: (row) => dateFormattedCell(row, 'dateApproved'),
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'dateApproved'),
        },

        {
          columnLabel: 'Loan Amount',
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'approvedInvoiceAmount'),
          cell: (row) => amountFormattedCell(row, 'approvedInvoiceAmount'),
        },
        {
          columnLabel: 'Due Date',
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'bankDueDate'),
          cell: (row) => dateFormattedCell(row, 'bankDueDate'),
        },

        {
          columnLabel: 'Loan Outstanding',
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'loanAmountOutstanding'),
          cell: (row) => amountFormattedCell(row, 'loanAmountOutstanding'),
        },

        {
          columnLabel: 'Bank',
          onSort: (direction: 'asc' | 'desc') => handleSorting(direction, 'bank'),
          selectorKey: 'bank',
        },
      ]}
      keyExtractor={'invoiceNum'}
      errorMsg={!!dataError}
      maxData={dataCount ?? 0}
      isLoading={dataLoading}
    />
  );
};
